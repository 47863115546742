export const ApplicationStatus = {
    APPROVED : 'Approved',
    REJECTED : 'Rejected',
    WITHDRAWAL: 'Withdrawal',
}

export const VisitedProperty = {
    In_Person : 'In-Person',
    Virtual : 'Virtual',
    No_Tour : 'No Tour',
}