// create a buildium modality functional component

import React, { useState, useContext, useEffect } from "react";
import ReactDOM from 'react-dom';
import { ApiContext } from "../../context/apiContext";
import { FlexDiv } from "../deal-detail-content/shared-styles";
import BuildiumUnitCard from "../buildium-unit-card/buildium-unit-card";
import { ModalOverlay, ModalWrapper, CloseButton, SpaceDiv, HeaderContainer, TitleHeader, ButtonRow, ConfirmButton, ModalBody, ContentSection } from "./buildium-modality.styles";
import { toast } from "react-toastify";
import Loader from "../loader/loader";
import LoaderCard from '../loading-cards/loading-card'

const BuildiumModality = ({dealId, isOpen, onClose}) => {
    const [selectedUnit, setSelectedUnit] = useState();
    const [buildiumUnits, setBuildiumUnits] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const api = useContext(ApiContext);

    useEffect(() => {
        const fetchData = () => {
            getBuildiumUnits(dealId)
        }
        fetchData()
    },[dealId, isOpen])


    const getBuildiumUnits = async (dealId) => {
        setShowLoader(true)
        try {
            const response = await api.get(`/api/buildium/units`, {deal_id: dealId})
            setBuildiumUnits(response)
        } catch(error) { 
            toast.error(error)
        }finally{
            setShowLoader(false);
        }
    }

    const saveBuildiumUnit = (event) => {
        event.preventDefault();
        // save buildium unit
        api.post(`/api/buildium/units/save`, {buildium_unit_id: selectedUnit.Id}, {deal_id:dealId}).then((response) => {
            toast.success("Buildium unit saved successfully")
            setSelectedUnit({});
            onClose();
        }).catch((error) => {
            toast.error("Failed to save buildium unit")
        })
    }

    const handleClose = () => {
        onClose()
    }

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <ModalOverlay>
            <ModalWrapper>
                <HeaderContainer>
                    <SpaceDiv>
                        <TitleHeader>Connect Buildium</TitleHeader>
                        <FlexDiv>
                            <CloseButton onClick={handleClose}>X</CloseButton>
                        </FlexDiv>
                    </SpaceDiv>
                </HeaderContainer>
                 <ModalBody>
                    Select the Buildium unit to connect to this deal
                    {
                        showLoader ? 
                        <LoaderCard size="small"/>
                        :
                        <>
                            {
                                buildiumUnits.map((unit, index) => 
                                    <BuildiumUnitCard 
                                        key = {index} 
                                        unit = {unit}
                                        onClick={()=> setSelectedUnit(unit)} 
                                        selected={selectedUnit === unit}
                                    />
                                )
                            }
                        </>
                    }
                </ModalBody>
                 <ButtonRow>
                    <ConfirmButton disabled={!selectedUnit} onClick={saveBuildiumUnit}>Confirm</ConfirmButton>
                </ButtonRow>
            </ModalWrapper>
        </ModalOverlay>,
        document.getElementById("modal-root")
    )
}
export default BuildiumModality;