import React, { useEffect } from 'react';

const BrevoChatWidget = () => {
  useEffect(() => {
    // Set your Brevo Conversations ID on the window object
    window.BrevoConversationsID = '65f824590266d852505f892d';

    // Create the global function if it doesn't exist
    window.BrevoConversations = window.BrevoConversations || function() {
      (window.BrevoConversations.q = window.BrevoConversations.q || []).push(arguments);
    };

    // Create the script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';

    // Append the script to the head
    document.head.appendChild(script);

    // Cleanup: remove the script when component unmounts (optional)
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // This component doesn't render any visible elements.
  return null;
};

export default BrevoChatWidget;
