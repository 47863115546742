import React, {useContext} from "react";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";
// import { formatCurrency } from "../../utilities/utility";
import {
    Card,
    // CardHeader,
    // PropertyImage,
    // PropertyDetails,
    // PropertyTitle,
    GridContainer,
    GridBox,
    GridTitle,
    GridContent,
    StatusBadge,
    // PropertyPrice,
    BulletIcon,
    ActionButton,
    RemindButton,
    RelistButton
} from "./renewal-card.styles";
import { ReactComponent as CalendarIcon } from "../../assets/calendar.svg"; // Adjust the path based on your setup
// import { ReactComponent as Wallet } from '../../assets/Wallet.svg';
import PropertyInfo from "../property-info/property-info";


const RenewalCard = ({ renewal, handleClick, initiateOffer, showRenewalModal, sendReminder, requestReplacement, relist, handleRenewalAction}) => {
    const {
        property,
        renewalStatus,
        totalTenants,
        latestUpdateDate,
        deadline,
        totalApplicants,
        renewalId
    } = renewal;

    const api = useContext(ApiContext)

    const handleButtonClick = (e, callback) => {
        e.stopPropagation(); // Prevent the Card's onClick from triggering
        callback && callback();
    };


    return (
        <Card onClick={() => handleClick(renewal.renewalId)}>
            <PropertyInfo
                property = {property}
                offerPrice = {renewal.latestOfferPrice || property.price}
            />
            {/* <CardHeader>
                <PropertyImage
                    src={property.image || "https://via.placeholder.com/80"}
                    alt="Property"
                />
                <PropertyDetails>
                    <PropertyTitle>{property.address || "Address Not Provided"}</PropertyTitle>
                    <PropertyPrice><Wallet stroke="#747474"/> {formatCurrency(renewal.latestOfferPrice || property.price)}</PropertyPrice>
                </PropertyDetails>
            </CardHeader> */}
            <GridContainer>
                <GridBox>
                    <GridTitle>Renewal Status</GridTitle>
                    <StatusBadge status={renewalStatus}><BulletIcon/>{renewalStatus}</StatusBadge>
                </GridBox>
                <GridBox>
                    <GridTitle>Total Tenants</GridTitle>
                    <GridContent>{totalTenants} Tenants</GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Move-Out Date</GridTitle>
                    <GridContent>
                        <CalendarIcon />
                        {property.moveOutDate || "N/A"}
                    </GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Deadline</GridTitle>
                    <GridContent>
                        <CalendarIcon />
                        {deadline || "No Deadline"}
                    </GridContent>
                </GridBox>
                <GridBox>
                    <GridTitle>Last Update</GridTitle>
                    <GridContent>
                        <CalendarIcon />
                        {latestUpdateDate || "N/A"}
                    </GridContent>
                </GridBox>
                {
                    renewalStatus === "Pending" && 
                    <ActionButton onClick={(e) => handleButtonClick(e, () => initiateOffer())}>Send Request</ActionButton>
                }
                {
                    (renewalStatus === "Replacement Requested") && 
                    <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('replacement-follow-up', renewalId))}>Remind</RemindButton>
                }
                {
                    (renewalStatus === "Awaiting Responses") && 
                    <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('remind', renewalId))}>Remind</RemindButton>
                }
                {
                    renewalStatus === "Replacement Applied" && 
                    <ActionButton>View Application</ActionButton>
                }
                {
                    renewalStatus === "Form Signing" && 
                    <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('remind', renewalId))}>Follow-Up</RemindButton>
                }
                {
                    renewalStatus === "Pending Payments" && 
                    <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('payment-follow-up', renewalId))}>Request Payment</RemindButton>
                }
                {
                    renewalStatus === "Partially Accepted" && 
                    <ActionButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('replacement', renewalId))}>Request Replacement</ActionButton>
                }
                {
                    renewalStatus === "Denied" && 
                    <RelistButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('relist', renewalId))}>Re-list</RelistButton>
                }
                {
                    renewalStatus === "Accepted" && 
                    <ActionButton onClick={(e) => handleButtonClick(e, () => showRenewalModal(renewal))}>
                        {totalApplicants === 0 ? "Send Renewal Form":"Send Lease"}
                    </ActionButton>
                }
            </GridContainer>
        </Card>
    );
};

export default RenewalCard;
