import styled from "styled-components";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px;
    display: ${props => props.animationState === "closed" ? 'none':'unset'};
    width: 60%;
    height: fit-content;
    overflow: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // overflow: hidden;
    z-index: 1001;
    padding: 30px;
    gap: 20px;
    position: relative;
`;


export const Title = styled.div`
    overflow: hidden;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


export const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    // padding: 20px;
`;

export const Section = styled.div`
    display: flex;
    gap: 15px;
    align-items: center;
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
`;

export const AddButton = styled.div`
    cursor: pointer;
    border-radius: 56px;
    background: var(--Light-Blue, #F0F8FF);
    display: flex;
    padding: 4px 18px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: var(--Blue-100, #064274);
    text-align: right;
    leading-trim: both;
    text-edge: cap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    /* line-height: 20px; */
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;

export const TableHeader = styled.thead`
    background-color: #fff;
`;

export const TableRow = styled.tr`
    &:hover {
        background-color: #fff;
    }
`;

export const TableCell = styled.td`
    padding: 12px;
    text-align: ${(props) => props.align || "left"};
    border-bottom: 1px solid #f1f1f1;
    font-size: ${(props) => (props.header ? "12px" : "14px")};
    font-weight: ${(props) => (props.header ? "400" : "500")};
    color: ${(props) => (props.header ? "#C4C4C4" : "#666")};
`;

export const Input = styled.input`
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    font-size: 12px;
    box-sizing: border-box;
`;

export const Button = styled.button`
    background-color: #064274;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 12px;

    &:hover {
        background-color: #052955;
    }
`;

export const DateButton = styled.button`
    border: none;
    background: transparent;
    cursor: pointer;

    svg {
        font-size: 16px;
        color: #666;
    }
`;

export const NoPaymentDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #ddd;
    /* border: 1px solid; */
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
`;

export const PaymentDiv = styled.div`
    display: flex;
    gap:5px;
    flex-direction: column;
`;

export const ButtonDiv = styled.div`
    display: flex;
    // padding: 10px 30px 10px;
    justify-content: flex-end;
    gap: 20px;
    bottom: 0;
    // position: absolute;
    // border-top: 1px solid #fafafa;
    width: -webkit-fill-available;
`;

export const NextButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 250px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const PreviousButton = styled(NextButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;


export const CheckBoxLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    // min-width: 400px;
    // width: 60%;
    // border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px;
    // padding: 5px 30px 5px 10px;
`;