import React, { useState } from 'react'
import { SectionTitle, Label, GapDiv, Section, NextButton, PreviousButton } from '../shared-styles'
import { VisitedProperty } from '../../../utilities/enum/application.enum';


const DocumentHandling = ({applicationData, handleChange, setIsFocus}) => {
    const [isDirectUpload, setIsDirectUpload] = useState(applicationData?.isDirectUpload || false)
    const [visitedProperty, setVisitedProperty] = useState(null);
    const [errors, setErrors] = useState({});
    const validate = () => {
        let newErrors = {};
        if (!visitedProperty) newErrors.visitedProperty = 'An input is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handleNext = () => {
        if(validate()){
            handleChange("isDirectUpload", isDirectUpload);
            if(isDirectUpload){
                setIsFocus('Package')
            }else{
                setIsFocus('Applicants')
            }
        }
    }


    const handleVisitedProperty = (value) => {
        setVisitedProperty(value);
        handleChange("visitedProperty", value);
    }
    return (
        <>
        <Section>
            <SectionTitle>How would you like to handle the application?</SectionTitle>
            <GapDiv style={{flexDirection:'column'}}>
                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'flex-start', gap:'5px'}}>
                    <input
                        type="radio"
                        value={false}
                        name="directUpload"
                        checked={isDirectUpload === false} 
                        onChange={(e) => setIsDirectUpload(false)}
                    />
                    The applicants will fill their application form and upload their own documents
                </Label>
                <Label style = {{color: '#2C2C2C', display:'flex', alignItems:'flex-start', gap:'5px'}}>
                    <input
                        type="radio"
                        value={true}
                        name="directUpload"
                        checked={isDirectUpload === true} 
                        onChange={(e) => setIsDirectUpload(true)}
                    />
                    I will upload the application package on behalf of the applicants
                </Label>
            </GapDiv>
            <Section style={{gap: '10x'}}>
            <SectionTitle>Has the Applicant visited the property? {errors?.visitedProperty && <span style={{color: 'red'}}>{errors.visitedProperty}</span>}</SectionTitle>
            <GapDiv>
                <Label style={{ color: '#2C2C2C', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                        type="radio"
                        name="visitedProperty"
                        value="In-Person"
                        checked={visitedProperty === VisitedProperty.In_Person}
                        onChange={() => handleVisitedProperty(VisitedProperty.In_Person)}
                    />
                    In-Person
                </Label>
                <Label style={{ color: '#2C2C2C', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                        type="radio"
                        name="visitedProperty"
                        value="Virtual"
                        checked={visitedProperty === VisitedProperty.Virtual}
                        onChange={() => handleVisitedProperty(VisitedProperty.Virtual)}
                    />
                    Virtual
                </Label>
                <Label style={{ color: '#2C2C2C', display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input
                        type="radio"
                        name="visitedProperty"
                        value="Virtual"
                        checked={visitedProperty === VisitedProperty.No_Tour}
                        onChange={() => handleVisitedProperty(VisitedProperty.No_Tour)}
                    />
                    No Tour
                </Label>
            </GapDiv>
        </Section>
            <GapDiv>
                <PreviousButton onClick={()=>setIsFocus('Property Details')}>Previous</PreviousButton>
                <NextButton onClick={handleNext}>{isDirectUpload === true? "Add applicants & upload package":"Add applicants"}</NextButton>
            </GapDiv>
            
        </Section>
        </>
    )
}

export default DocumentHandling