import React, { useState, useContext, useEffect } from "react";
import {
    Title,
    FilterBarContainer,
    TabContentHeader,
    TabHolder,
    Tab
} from './renewals-filter.styles'; // Update the styles file
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify';

const RenewalsFilter = ({ handleFilter }) => {
    const [focusTab, setFocusTab] = useState('Pending');
    const api = useContext(ApiContext);
    const [renewalsCount, setRenewalsCount] = useState({
        pending: 0,
        underNegotiation: 0,
        pendingPayments: 0,
        renewed: 0,
        relisted: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await api.get("/api/renewal/count"); // Update the API endpoint
                setRenewalsCount({
                    pending: data.pending || 0,
                    underNegotiation: data.underNegotiation || 0,
                    pendingPayments: data.pendingPayments || 0,
                    renewed: data.renewed || 0,
                    relisted: data.relisted || 0,
                });
            } catch (error) {
                // toast.error(`There was an error loading the data: ${error.message}`);
            }
        };

        fetchData();
    }, []);

    const onTabClick = (value) => {
        setFocusTab(value);
        handleFilter(value);
    };

    return (
        <FilterBarContainer>
            <Title>Upcoming Renewals</Title>
            <TabContentHeader>
                <TabHolder>
                    <Tab
                        onClick={() => onTabClick('Pending')}
                        active={focusTab === "Pending"}
                    >
                        Pending <span>{renewalsCount.pending}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Under Negotiation')}
                        active={focusTab === "Under Negotiation"}
                    >
                        Under Negotiation <span>{renewalsCount.underNegotiation}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Pending Payments')}
                        active={focusTab === "Pending Payments"}
                    >
                        Pending Payments <span>{renewalsCount.pendingPayments}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Renewed')}
                        active={focusTab === "Renewed"}
                    >
                        Renewed <span>{renewalsCount.renewed}</span>
                    </Tab>
                    <Tab
                        onClick={() => onTabClick('Re-listed')}
                        active={focusTab === "Re-listed"}
                    >
                        Re-listed <span>{renewalsCount.relisted}</span>
                    </Tab>
                </TabHolder>
            </TabContentHeader>
        </FilterBarContainer>
    );
};

export default RenewalsFilter;
