import React, { useContext, useState } from "react";
import PropertyInfo from '../property-info/property-info'
import RejectionModal from "../reject-modal/reject-modal";
import ApplicationCheckUpdate from "../application-check-update/application-check-update";
import { compareDateTime } from '../../utilities/utility';
import { UserContext } from "../../context/userContext";

import {
    Card,
    AgentName,
    StatusBadge,
    BulletIcon,
    SpaceDiv,
    FlexDiv,
    FMRPaid,
    Applicant,
    DetailItem,
    MoreBadge,
    NameDiv,
    AssignButton,
    FlexDivGapSmall,
    RejectButton
} from './application-card.styles'


const ApplicationCard = ({application, setActiveTab, toggleModal, updateApplication}) => {

    const {property, agent, status, applicants} = application
    const [rejectModal, setRejectModal] = useState(false);
    const [fmrModal, setFmrModal] = useState(false);
    const { user } = useContext(UserContext)

    const cardClick = (event) => {
        // console.log(event.target)
        if(event.target.innerText === "Approve" || event.target.innerText === "Assign" 
        || event.target.innerText === "Reject" || event.target.innerText === "FMR Paid"){
            event.stopPropagation();
        }else{
            setActiveTab('Review');
            toggleModal();
        }
    }

    const assignClick = (event) => {
        event.stopPropagation();
        setActiveTab('Assign');
        toggleModal();
    }

    const approveClick = (event) => {
        event.stopPropagation();
        setActiveTab('Approve');
        toggleModal();
    }

    const rejectClick = (event) => {
        event.stopPropagation();
        setRejectModal(true);
    }

    const fmrPaidClick = (event) => {
        event.stopPropagation();
        setFmrModal(true);
    }
    
    return (
        <Card onClick = { (event) => cardClick(event)}>
            <PropertyInfo
                property={property}
                offerPrice={application.offerPrice}
            />
            <SpaceDiv style={{marginTop: '5px'}}>
                <AgentName>{agent.name}</AgentName>
                <FlexDivGapSmall>
                    {
                        application.depositPaid &&
                        <FMRPaid>
                            <BulletIcon/>
                            Deposit Paid
                        </FMRPaid>
                    }
                    <StatusBadge status={status}>
                        <BulletIcon/>
                        {status}
                    </StatusBadge>
                </FlexDivGapSmall>
            </SpaceDiv>
            <NameDiv spacing = {applicants.length - 1 > 0}>
                <Applicant>
                    Name of Applicants
                </Applicant>
                <SpaceDiv>
                    <FlexDiv style={{gap: '5px' }}>
                        <DetailItem>
                            {applicants[0]?.name}
                        </DetailItem>
                        {
                            applicants.length - 1 > 0 &&
                            <MoreBadge>+ {applicants.length - 1} more</MoreBadge>
                        }
                    </FlexDiv>
                        {
                            !application.assignedTimeStamp ? 
                            <DetailItem>Submitted {compareDateTime(application.submittedTimeStamp)}</DetailItem>
                            :
                            <DetailItem>Assigned {compareDateTime(application.assignedTimeStamp)}</DetailItem>
                        }
                </SpaceDiv>
            </NameDiv>
            <FlexDiv style={{gap: '10px' , marginTop: '7px'}}>
                {
                    user?.userType !== 'view_only' && application.status !== "Rejected"  && application.status !== "Approved" && 
                    <RejectButton onClick={rejectClick}>Reject</RejectButton>
                }
                {
                    application.status !== "Under Review" && application.status !== "Approved" && application.status !== "Rejected" 
                    && user?.userType === "leasing_coordinator" &&
                    <AssignButton onClick={assignClick}>Assign</AssignButton>
                }
                {
                    application.status !== "Rejected"  && application.status !== "Approved" && user?.userType === "admin" && 
                    <AssignButton onClick={approveClick}>Approve</AssignButton>
                }
                {
                    user?.isHoldingDepositRequired && (
                        user?.userType !== 'view_only' && application.status === "Approved" && !application.depositPaid && 
                        <AssignButton onClick={fmrPaidClick}>Add Deposit & Create Deal</AssignButton>
                    )
                }
            </FlexDiv>
            {
                rejectModal &&
                <RejectionModal
                    onClose = {() => setRejectModal(false)}
                    applicationId = {application.id}
                    updateApplication = {updateApplication}
                />
            }
            {
                fmrModal && 
                <ApplicationCheckUpdate
                    applicationId={application.id}
                    updateApplication={updateApplication}
                    onClose={() => setFmrModal(false)}
                />
            }
        </Card>
    )
}

export default ApplicationCard