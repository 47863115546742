import React, { useContext, useState } from "react";
import PropertyInfo from '../property-info/property-info'
import RejectionModal from "../reject-modal/reject-modal";
import ApplicationCheckUpdate from "../application-check-update/application-check-update";
import { compareDateTime } from '../../utilities/utility';
import { UserContext } from "../../context/userContext";
import { ApiContext } from "../../context/apiContext";

import {
    BulletIcon,
    Card,
    StatusBadge,
} from './buildium-unit-card.styles'

import { PropertyCard, PropertyTitle, PropertyText, Price, Status } from "./buildium-unit-card.styles";



const BuildiumUnitCard = ({unit, onClick, selected}) => {
    return (
        // <Card>
            <PropertyCard selected={selected}  onClick = {onClick} >
                <PropertyTitle>{unit.BuildingName} 
                    <PropertyText>
                    <strong>Unit:</strong> {unit.UnitNumber}
                    </PropertyText> 
                    <StatusBadge status = {unit.IsUnitOccupied}>
                        <BulletIcon/>
                        { unit.IsUnitOccupied === false ? "Not Occupied" : "Occupied" } 
                    </StatusBadge>
                </PropertyTitle>
                {/* <PropertyText><strong>Address:</strong> {unit.Address.AddressLine1}, {unit.Address.City}, {unit.Address.State} {unit.Address.PostalCode}</PropertyText> */}
                <PropertyText><strong>Bedrooms:</strong> {unit.UnitBedrooms}  |  <strong>Bathrooms:</strong> {unit.UnitBathrooms}</PropertyText>
                {/* <PropertyText><strong>Size:</strong> {unit.UnitSize} sqft</PropertyText> */}
                {/* <Price>${unit.MarketRent}/month</Price> */}
            </PropertyCard>
        // </Card>
    )
}

export default BuildiumUnitCard