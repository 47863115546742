import styled from "styled-components";
export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1001;
    gap: 20px;
    position: relative;
    padding: 30px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;

export const HeaderContainer = styled.div`
    // padding: 30px 30px 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const TitleHeader = styled.h1`
    font-size: 22px;
    color: #333;
    margin: 0;
`;

export const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 18px;
    color: #e74c3c;
    cursor: pointer;
    margin-left: 10px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const ConfirmButton = styled.button`
    padding: 10px;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    padding: 12px;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#064274")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const ButtonRow = styled.div`
    // margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    // padding-inline: 24px;
    // padding-bottom: 8px;
`;

export const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    // padding: 0px 30px 30px 30px;
    padding: 5px;
    max-height: 400px;
    overflow-y: scroll;
`;

export const ContentSection = styled.div`
    // padding: 20px;
    display: flex;
    flex-wrap: wrap;
    // gap: 15px 20px;
    overflow-y: hidden;
    overflow-x: hidden;
    height: ${(props) => `calc(100vh - ${props.headerHeight}px)`};
    justify-content: flex-start;
    align-content: flex-start;
`;
