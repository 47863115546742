// StyledTableComponents.js
import styled, {css} from 'styled-components';
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet

export const TableContainer = styled.div`
  width: 100%;
  border-collapse: collapse;
  border-radius: 7px;
  background: var(--White, #FFF);
  padding: 0px 20px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
  overflow-x: auto; // Ensures the table is scrollable on smaller screens
  overflow-y: auto;
  height: 100%;

`;

export const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; // Make sure the row spans the full width
  padding: 10px;
  box-sizing: border-box; // Include padding in the element's total width and height
  cursor: pointer;
  border-radius: 7px;
  background-color: ${props => props.incomplete && '#FFFACD50'};//'#FFD1D1'};
  //#E45A5C

  &:hover{
    background-color: ${props => props.incomplete ? '#FFFACD':'#f1f1f1'};//'#FFD1D1'};
    // background-color: #f1f1f1;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #f1f1f1;
  }

  &:nth-child(1){
    position: sticky;
    top: 0; /* Adjust if you have a top bar or padding you need to account for */
    background: white; /* Or any color that matches your table header's background */
    z-index: 1; /* Ensure
    position: relative;
    cursor: unset;
  }
`;

export const TableCell = styled.div`
  flex: 1; // Allocate equal space to each cell, adjust the number as needed for a different ratio
  text-align: left;
  padding: 0px 8px;
  min-width: 0; // Allows the cell to shrink below content size
  white-space: nowrap; // Prevents line breaks inside cells
  overflow: hidden; // Hide content that overflows the cell
  text-overflow: ellipsis; // Add an ellipsis to truncated content

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:nth-child(1){
    flex: 2.5;
  }
    &:nth-child(3){
    flex: 1.5;
  }
  &:nth-child(7){
    flex: 1.5;
  }
  &:nth-child(8){
    flex: 0.5;
    background: transparent;
    border: none;
    color: #3498DB;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1A5276;
    }
  }
`;

export const TableHeaderCell = styled(TableCell)`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ColorSpan = styled.span`
  color: var(--Light-Grey, #A4A4A4);
  leading-trim: both;
`;

export const TableSubCell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const BulletIcon = styled(FaCircle)`
  font-size: 8px; // Size it to look like a bullet
  vertical-align: middle; // Align with the text
  margin-right: 4px; // Space after the bullet
`;

export const StatusBadge = styled.span`
  ${({ status }) => status && css`
    background-color: ${statusColors[status].background};
    color: ${statusColors[status].color};
  `}
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  user-select: none;
  width: fit-content;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const statusColors = {
  "On Market": { background: '#E3FFE6', color: '#1CCB00' },
  "New": {background: '#B2EBF2', color: '#00796B' },
  "Pending": {background: '#FFF4E5', color: '#FFA500' },
  "Off Market": {background: '#FFD1D1', color: '#D8000C' }
};

