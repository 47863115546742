import styled from "styled-components";
import { VisitedProperty } from "../../utilities/enum/application.enum";

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
`;

export const ModalWrapper = styled.div`
    background: white;
    border-radius: 10px;
    display: ${props => props.animationState === "closed" ? 'none':'unset'};
    width: 60%;
    height: 70%;
    // overflow: auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    z-index: 1001;
    padding: 30px;
    gap: 20px;
    position: relative;
`;

export const Title = styled.div`
    overflow: hidden;
    color: var(--Greyish-Black, #323232);
    leading-trim: both;

    text-edge: cap;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
`;


export const CloseButton = styled.button`
    cursor: pointer;
    border: none;
    background: #f7f7f7;
    width: 35px;
    padding: 8px;
    height: 35px;
    border-radius: 35px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: fit-content;
  overflow: auto;
`;

export const WrapDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // overflow: auto;
`;

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SectionTitle = styled.div` // Changed to h2 for semantic HTML, adjust as necessary
    color: #2C2C2C;
    font-size: 16px;
    font-weight: 600;
`;

export const Label = styled.label`
    display: flex;
    gap: 5px;
    align-items: center;
    color: #2C2C2C;
    font-size: 14px;

    input {
        margin: unset !important;
    }
`;

export const PaymentContainer = styled.div`
    padding: 20px 20px 10px 20px;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
    gap: 5px;
    display: flex;
    width: 60%;
    min-width: 400px;
    flex-direction: column;
    // background: #F9F9F9;

`;

export const PaymentTitle = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    // margin-bottom: 10px;

    div {
        color: var(--Light-Grey, #A4A4A4);
        leading-trim: both;
        display: flex;

        text-edge: cap;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &:nth-child(1){
            width: 200px;
        }

        &:nth-child(2){
            width: 118px;
            justify-content: start;
        }

        &:nth-child(3){
            width: 100px;
            // margin-left: auto;
            justify-content: end;
        }
    }

`;

export const Payment = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
    
    border-bottom: 1px solid #EEE;

    &:last-child {
        border-bottom: unset;
    }

    div{
        color: var(--Greyish-Black, #323232);
        /* Body/Large */
        font-family: Avenir;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 10px;

        span { 
            background-color: #E3FFE6;
            color: #1CCB00;
            border-radius: 15px; // Fully rounded corners
            padding: 2px 10px; // Vertical and horizontal padding
            font-size: 11px; // Font size
            font-weight: 600; // Medium font weight
            cursor: pointer; // Cursor changes to pointer to indicate it's clickable
            user-select: none; // Prevent text selection
            display: inline-flex; // Use inline-flex to center content
            align-items: center; // Vertical align to center
            justify-content: center; // Horizontal align to center
            margin: 5px;
        }

        &:nth-child(1){
            width: 200px;
        }

        &:nth-child(3){
            width: 100px;
            // margin-left: auto;
            justify-content: end;
        }
    }

    input {
        color: #2C2C2C;
        font-size: 12px;
        padding: 5px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        // flex-direction: column;
        border: 1px solid var(--F1, #F1F1F1);
        border-radius: 7px;
    }
`;

export const Heading = styled.div`
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 500;
`;

export const FlexColumnDiv = styled.div`
    display: flex;
    gap: 5px;
    flex-direction: column;
`;


export const SubmitButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;

export const PreviousButton = styled(SubmitButton)`
    background-color: #F7F7F7;
    color: #323232;
    font-weight: 400;
    // cursor: pointer;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;


export const TextInput = styled.textarea`
    display: flex;
    padding: var(--12, 12px);
    font-family: Avenir;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
`;

const color = ({status}) => {
    
    if (status === VisitedProperty.In_Person){
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        ` 
    } else if (status === VisitedProperty.No_Tour){
        return `
            background-color: #FFD1D1; // soft red
            color: #D8000C; // darker red
        `;
    } else if (status === VisitedProperty.Virtual){
        return `
            background-color: #fff49b; // soft lavender
            color: #DAA520; // darker purple
        `
    }
}

export const VisitedPropertyBadge = styled.span`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 15px; // Font size
    font-weight: 600; // Medium font weight
    cursor: pointer; // Cursor changes to pointer to indicate it's clickable
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    justify-content: center; // Horizontal align to center
    margin-left: 35%;
    width: 30%;
`;