import React, { useState, useMemo, useEffect } from "react";
import PropertyInfo from "../property-info/property-info";
import PaymentInfo from "../deal-detail-content/payment-info/payment-info";
import PaymentBox from "../payment-box/payment-box";
import RenewalPackage from './renewal-package/renewal-package'
import {
    Container,
    Canvas,
    TabContentHeader,
    TabHolder,
    Tab,
    CanvasSmall,
    FlexDiv,
    NavigationButton,
    SpaceDiv,
    TextNav,
    FilterBarContainer,
    ActionButton,
    RemindButton,
    RelistButton,
    ButtonDiv,
    MainContent,
    BulletIcon,
    StatusBadge
} from "./renewals-detail.styles";
import TenantInfo from "./tenant-info/tenant-info";
import Forms from "./forms/forms";
import Offers from "./offers/offers";


const RenewalDetail = ({ 
    focusRenewal, nextRenewalId, previousRenewalId, totalRenewals, currentIndex, 
    handleClick, setFocusRenewal, initiateOffer, showRenewalModal, showLeaseRenewalForm, setIsLeaseModalOpen, handleRenewalAction
}) => {
    const [activeTab, setActiveTab] = useState("Tenants");
    const [focusPayment, setFocusPayment] = useState({
        rentType: '',
        rentValue: {}
    });

    const hasFocusPayment = focusPayment.rentType !== '';

    const handleSelectedPayment = ( rentType, rentValue) => {
        if(focusPayment.rentType === rentType){
            resetFocusPayment();
        }else {
            setFocusPayment({
                rentType: rentType,
                rentValue: rentValue
            })
        }
    }

    const updatePayment = (updateData, depositType) => {
        setFocusRenewal((prev) => {
            let updated = {
                ...prev,
                renewalDetail: {
                    ...prev.renewalDetail,
                    payments: {
                        ...prev.renewalDetail.payments,
                        [depositType]: updateData[depositType],
                    },
                    totalAmountDue: prev.renewalDetail.totalAmountDue - updateData[depositType].amount,
                    totalAmountPaid: prev.renewalDetail.totalAmountPaid + updateData[depositType].amount,
                }
            };
            console.log('1', updated)
            if ( updated.renewalDetail.totalAmountDue === 0){
                updated = {
                    ...updated,
                    renewalDetail: {
                        ...updated.renewalDetail,
                        renewalStatus: 'Renewed'
                    }
                }
            }
            console.log('2',updated)
            return updated;
        });
    };


    const resetFocusPayment = () => {
        setFocusPayment({
            rentType: '',
            rentValue: {}
        })
    }

    const onTabClick = (tab) => {
        setActiveTab(tab);
    };


    const handleButtonClick = (e, callback) => {
        e.stopPropagation(); // Prevent the Card's onClick from triggering
        callback && callback();
    };


    const renderTabContent = useMemo(() => {
        switch(activeTab){
            case 'Tenants':
                return <TenantInfo
                    tenants = {focusRenewal.tenants}
                    applicants = {focusRenewal.applicants}
                    renewalId = {focusRenewal.renewalId}
                    applicationStatus = {focusRenewal.applicationStatus}
                    setActiveTab = {setActiveTab}
                />
            case "Forms":
                return <Forms
                    tenants = {focusRenewal.tenants}
                    applicants = {focusRenewal.applicants}
                    totalApplicants = {focusRenewal.totalApplicants}
                    renewalId = {focusRenewal.renewalId}
                    lease = {focusRenewal.leases}
                    renewalForm = {focusRenewal.renewalForm}
                    showLeaseRenewalForm =  {() => showLeaseRenewalForm(focusRenewal)}
                    showRenewalModal = {() => showRenewalModal(focusRenewal)}
                />
            case "Offers":
                return <Offers
                    offers = {focusRenewal.offers}
                    focusRenewal = {focusRenewal}
                    initiateOffer={initiateOffer}
                />
            case "Application":
                return <RenewalPackage
                    focusRenewal = {focusRenewal}
                    setFocusRenewal = {setFocusRenewal}
                    showRenewalModal = {showRenewalModal}
                />
            case "Payments":
                return <PaymentInfo
                    dealId = {focusRenewal?.dealId}
                    dealDeposits = {focusRenewal?.payments}
                    amountDue = {focusRenewal?.totalAmountDue}
                    amountPaid = {focusRenewal?.totalAmountPaid}
                    handleSelectedPayment = {handleSelectedPayment}
                    focusPayment = {focusPayment}
                    updateDeal = {updatePayment}
                />
        }
    },[focusRenewal, activeTab])

    return (
        <Container>
            <FilterBarContainer>
                <SpaceDiv>
                    <FlexDiv>
                        <NavigationButton onClick={() => handleClick()}>{"<"}</NavigationButton>
                        <TextNav>Renewals/
                            <span>Detail View</span>
                        </TextNav>
                    </FlexDiv>
                    <FlexDiv>
                        {
                            previousRenewalId && 
                            <NavigationButton onClick={() => handleClick(previousRenewalId)}>{"<"}</NavigationButton>
                        }
                        <span>
                            Renewal {currentIndex + 1} of {totalRenewals}
                        </span>
                        {
                            nextRenewalId && 
                            <NavigationButton onClick={() => handleClick(nextRenewalId)}>{">"}</NavigationButton>
                        }
                    </FlexDiv>
                </SpaceDiv>
                <SpaceDiv>
                    <FlexDiv style={{gap:'20px'}}>
                        <PropertyInfo
                            property={focusRenewal.property}
                            offerPrice={focusRenewal.latestOfferPrice}
                        />
                        <StatusBadge status={focusRenewal.renewalStatus}><BulletIcon/>{focusRenewal.renewalStatus}</StatusBadge>
                    </FlexDiv>
                    
                    <ButtonDiv>
                        {
                            focusRenewal.renewalStatus === "Pending" && 
                            <ActionButton onClick={(e) => handleButtonClick(e, () => initiateOffer(focusRenewal, true))}>Send Request</ActionButton>
                        }
                        {
                            (focusRenewal.renewalStatus === "Awaiting Responses") && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('remind', focusRenewal.renewalId))}>Remind</RemindButton>
                        }
                        {
                            (focusRenewal.renewalStatus === "Replacement Requested") && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('replacement-follow-up', focusRenewal.renewalId))}>Remind</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Form Signing" && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('remind', focusRenewal.renewalId))}>Follow-Up</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Pending Payments" && 
                            <RemindButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('payment-follow-up', focusRenewal.renewalId))}>Request Payment</RemindButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Partially Accepted" && 
                            <ActionButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('replacement', focusRenewal.renewalId))}>Request Replacement</ActionButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Denied" && 
                            <RelistButton onClick = {(e) => handleButtonClick(e, () => handleRenewalAction('relist', focusRenewal.renewalId))}>Re-list</RelistButton>
                        }
                        {
                            focusRenewal.renewalStatus === "Accepted" && 
                            <ActionButton onClick={(e) => handleButtonClick(e, () => showRenewalModal(focusRenewal))}>
                                {focusRenewal.totalApplicants === 0 ? "Send Renewal Form":"Send Lease"}
                            </ActionButton>
                        }
                    </ButtonDiv>
                </SpaceDiv>
            </FilterBarContainer>
            <MainContent>
                <Canvas showSmallCanvas = {hasFocusPayment}>
                    <TabContentHeader>
                        <TabHolder>
                            <Tab
                                onClick={() => onTabClick("Tenants")}
                                active={activeTab === "Tenants"}
                            >
                                Tenants
                            </Tab>
                            {
                                focusRenewal.renewalStatus !== "Pending" &&
                                <Tab
                                    onClick={() => onTabClick("Offers")}
                                    active={activeTab === "Offers"}
                                >
                                    Offers
                                </Tab>
                            }
                            
                            {
                                focusRenewal.totalApplicants > 0 &&
                                <Tab
                                    onClick = {() => onTabClick("Application")}
                                    active={activeTab === "Application"}
                                >
                                    Application Package
                                </Tab>
                            }
                            {
                                (focusRenewal.renewalStatus === "Form Signing" || focusRenewal.renewalStatus === "Pending Payments" 
                                    || focusRenewal.renewalStatus === "Renewed") &&
                                <Tab
                                    onClick={() => onTabClick("Forms")}
                                    active={activeTab === "Forms"}
                                >
                                    {
                                        focusRenewal.totalApplicants > 0 ? "Lease": "Renewal Forms"
                                    }
                                </Tab>
                            }
                            {
                                (focusRenewal.renewalStatus === "Pending Payments" || focusRenewal.renewalStatus === "Form Signing" 
                                || focusRenewal.renewalStatus === "Renewed") &&
                                <Tab
                                    onClick={() => onTabClick("Payments")}
                                    active={activeTab === "Payments"}
                                >
                                    Payments
                                </Tab>
                            }
                        </TabHolder>
                    </TabContentHeader>
                    {renderTabContent}
                </Canvas>
                <CanvasSmall showSmallCanvas={hasFocusPayment}>
                    <PaymentBox
                        focusPayment = {focusPayment}
                        updateDeal = {updatePayment}
                        dealId = {focusRenewal?.dealId}
                        onClose = {() => resetFocusPayment()}
                    />
                </CanvasSmall>
            </MainContent>
        </Container>
    )
}

export default RenewalDetail