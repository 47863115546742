import React, { useState, useContext } from "react";
import SendMessageModal from "../../message-modality/message-modality";
import BuildiumModality from '../../buildium-modality/buildium-modality';
import { ApiContext } from "../../../context/apiContext";
import { UserContext } from "../../../context/userContext"
import { formatDate } from '../../../utilities/utility'
import { toast } from 'react-toastify'

import {
    Canvas,
    AgentCard,
    AgentName,
    SubscriptText,
    IconButton,
    FlexDivColumn,
    DateText,
    LeasePackageDiv,
    CreateLeaseDiv,
    ConnectBuildiumDiv,
    BuildiumConnectedBadge,
    LeaseCard,
    Heading,
    FlexDiv,
    Th,
    ApplicantName,
    BulletIcon,
    StatusBadge,
    ContactDiv,
    StyledTable,
    TableRow,
    TableCellWrapper,
    DateDiv,
    IconDiv,
    FlexDivCenter,
    EmailName
} from '../shared-styles'

import { ReactComponent as Message } from '../../../assets/Message.svg';
import { ReactComponent as Download } from '../../../assets/Download.svg';
import { ReactComponent as Reminder } from '../../../assets/reminder.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import ApplicantsGuarantorsForm from "../../applicant-guarantor-modality/applicant-guarantor-modality";

const LeaseInfo = ({ dealId, applicants, guarantors, leaseDoc, leaseDue, agent, setFocusTab, activateModal, isBuildiumEnabled, isBuildiumConnected, updateDeal, leaseSentOut}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);
    const [isBuildiumModalOpen, setIsBuildiumModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const api = useContext(ApiContext)
    const { user } = useContext(UserContext)

    const handleOpenModalForAll = () => {
        setTenantsToSend(applicants); // Set all tenants
        setIsModalOpen(true);
    };

    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const sendReminder = async (id, type) => {
        try {
            const data = await api.get(`/api/lease/lease_reminder`,{id: id, type: type});
            toast.info(`Reminder sent to ${type}`);
        }catch(error){
            toast.error(error);
        }
    }


    const activateBuildiumModal = () => {
        setIsBuildiumModalOpen(true)
    }

    const deactivateBuildiumModal = () => {
        setIsBuildiumModalOpen(false)
    }

    const allApplicantsLeaseStatusCompleted = applicants.every(applicant => applicant.leaseStatus === 'Completed')

    return (
        <Canvas>
            <FlexDiv>
                <AgentCard>
                    <div style = {{display:'flex', flexDirection:'column', gap:'3px'}}>
                        <AgentName>{agent?.name}</AgentName>
                        <EmailName><strong>Email:</strong> {agent.email}</EmailName>
                        <EmailName><strong>Phone:</strong> {agent.phone}</EmailName> 
                        <SubscriptText><strong>Brokerage:</strong> {agent.brokerage}</SubscriptText>
                    </div>
                    <IconButton onClick={() => setFocusTab('Chat')}>
                        <Message/>
                    </IconButton>
                </AgentCard>
                <LeaseCard leaseDoc = {leaseDoc}>
                    {
                        leaseDue &&
                        <DateDiv>
                            <SubscriptText>Lease Due Date:</SubscriptText>
                            <DateText> {formatDate(new Date(leaseDue)).formattedDate}</DateText>
                        </DateDiv>   
                    }
                    {
                        leaseDoc ?
                        <LeasePackageDiv onClick={activateModal}>Lease Agreement <Download/></LeasePackageDiv>
                        :
                        <CreateLeaseDiv onClick={activateModal}><span>+</span><span>Create Lease</span></CreateLeaseDiv>
                    }
                    
                    {
                        isBuildiumEnabled && !isBuildiumConnected &&
                        <ConnectBuildiumDiv disabled={!allApplicantsLeaseStatusCompleted || !leaseDoc} onClick={()=>activateBuildiumModal()}
                        >
                            Connect Buildium
                        </ConnectBuildiumDiv>}
                    {
                        isBuildiumConnected && 
                        <BuildiumConnectedBadge hoverNone={true}>Buildium Already Connected</BuildiumConnectedBadge>
                    }
                </LeaseCard>
            </FlexDiv>
            <FlexDivCenter>
                <Heading>Lease & Documents Status</Heading>
                {!leaseSentOut &&  <IconButton onClick={() => setIsEditMode(true)}>
                    <EditIcon />
                    </IconButton>
                }
            </FlexDivCenter>
            <FlexDivColumn>
            {isEditMode ? (
                <ApplicantsGuarantorsForm 
                  applicants={applicants} 
                  guarantors={guarantors} 
                  updateDeal = {updateDeal}
                  dealId = {dealId}
                  onClose={() => setIsEditMode(false)} 
                />
            ):
                  <FlexDivColumn style={{gap:'0px'}}>
                    <div>Applicants</div>
                    <StyledTable>
                        <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                            <tr>
                                <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                                <Th><TableCellWrapper>Lease Status</TableCellWrapper></Th>
                                <Th><TableCellWrapper>Contact</TableCellWrapper></Th>
                                <Th><TableCellWrapper><ContactDiv onClick={handleOpenModalForAll}>Email All</ContactDiv></TableCellWrapper></Th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            applicants?.map(applicant => 
                                <TableRow>
                                    <ApplicantName><TableCellWrapper>{applicant.name}</TableCellWrapper></ApplicantName>
                                    <td>
                                        <TableCellWrapper>
                                            <StatusBadge status = {applicant.leaseStatus}>
                                                <BulletIcon/>
                                                {applicant.leaseStatus}
                                            </StatusBadge>
                                        </TableCellWrapper>
                                    </td>
                                    <ApplicantName>
                                        <TableCellWrapper>
                                                {/* <BulletIcon/> */}
                                            {applicant?.phone || "-"} / {applicant?.email}
                                        </TableCellWrapper>
                                    </ApplicantName>
                                    <td>
                                        <IconDiv>
                                            <TableCellWrapper>
                                                <IconButton onClick={() => handleOpenModalForOne(applicant)}>
                                                    <Message/>
                                                </IconButton>
                                            </TableCellWrapper>
                                            {
                                                leaseDoc &&
                                                <TableCellWrapper>
                                                    <IconButton 
                                                        onClick = {() => sendReminder(applicant.id, 'applicant')}
                                                    >
                                                        <Reminder/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                            }
                                        </IconDiv>
                                    </td>
                                </TableRow>
                            )
                        }
                        </tbody>
                    </StyledTable>
                    <br/>
                    {
                        user?.isGuarantorSignatureRequired && guarantors?.length > 0 &&
                        <>
                            <div>Guarantors</div>
                            <StyledTable>
                                <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                                    <tr>
                                        <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                                        <Th><TableCellWrapper>Lease Status</TableCellWrapper></Th>
                                        <Th><TableCellWrapper>Contact</TableCellWrapper></Th>
                                        <Th><TableCellWrapper><ContactDiv onClick={handleOpenModalForAll}>Email All</ContactDiv></TableCellWrapper></Th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    guarantors?.map(guarantor => 
                                        <TableRow>
                                            <ApplicantName><TableCellWrapper>{guarantor.name}</TableCellWrapper></ApplicantName>
                                            <td>
                                                <TableCellWrapper>
                                                    <StatusBadge status = {guarantor.leaseStatus}>
                                                        <BulletIcon/>
                                                        {guarantor.leaseStatus}
                                                    </StatusBadge>
                                                </TableCellWrapper>
                                            </td>
                                            <ApplicantName><TableCellWrapper>{guarantor?.phone || "-"} / {guarantor?.email}</TableCellWrapper></ApplicantName>
                                            {/* <td>
                                                <TableCellWrapper>
                                                    <StatusBadge status = {applicant.w9Status}>
                                                        <BulletIcon/>
                                                        {applicant.w9Status}
                                                    </StatusBadge>
                                                </TableCellWrapper>
                                            </td> */}
                                            <td>
                                                <IconDiv>
                                                    <TableCellWrapper>
                                                        <IconButton onClick={() => handleOpenModalForOne(guarantor)}>
                                                            <Message/>
                                                        </IconButton>
                                                    </TableCellWrapper>
                                                    {
                                                        leaseDoc &&
                                                        <TableCellWrapper>
                                                            <IconButton 
                                                                onClick = {() => sendReminder(guarantor.id, 'guarantor')}
                                                            >
                                                                <Reminder/>
                                                            </IconButton>
                                                        </TableCellWrapper>
                                                    }
                                                </IconDiv>
                                            </td>
                                        </TableRow>
                                    )
                                }
                                </tbody>
                            </StyledTable>
                        </>
                    }
                </FlexDivColumn>
}
            </FlexDivColumn>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={applicants}
                setTenantsToSend={setTenantsToSend}
            />
            {
                isBuildiumModalOpen &&
                <BuildiumModality 
                dealId={dealId}
                isOpen={isBuildiumModalOpen}
                onClose={deactivateBuildiumModal}
            />}
        </Canvas>
    )
}

export default LeaseInfo