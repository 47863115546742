import styled from "styled-components";

export const Canvas = styled.div`
    padding: 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    scroll: auto;
`;

export const AgentName = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Title/Small */
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const AgentCard = styled.div`
    display: flex;
    gap: 10px;
    /* max-width: 400px; */
    /* align-items: center; */
    justify-content: space-between;
    border: 1px solid #f3f3f3;
    width: fit-content;
    border-radius: 10px;
    padding: 15px;
    min-width: 250px;
    align-items: center;
`;

export const IconDiv = styled.div`
    display: flex;
    gap: 5px;
`;

export const IconButton = styled.button`
    background: none;
    border: none;
    aspect-ratio : 1 / 1;
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    // padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;   
    
    .svg {
        height: 25px;
        width: 25px;
    }
`;


export const ApplicantDetails = styled.div`
    display: flex;
    justify-content: space-between;
    min-width: 250px;
    align-items: center;
`;

export const GuarantorDetails = styled(ApplicantDetails)`
    min-width: 250px;
`;

export const EmailName = styled.div`
    color: var(--Greyish-Black, #323232);
    /* Body/Large */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    cursor: unset;

    // &:hover {
    //     text-decoration: underline;
    // }
`;