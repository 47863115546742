import React, { useEffect} from "react";
import moment from 'moment';
import {useQuill} from 'react-quilljs'
import 'quill/dist/quill.snow.css'

import {
    CommentInputContainer,
    Input,
    IconDiv,
    ReplyBox,
    ReplyHeader,
    ReplyDate,
    ReplyName,
    ReplyText,
    InputContainer,
    CloseButton,
    AttachmentPreview,
    ReplyContainer,
    PdfAttachmentPreview
} from './chat-input.styles';

import { ReactComponent as Attach } from '../../assets/attach.svg';
import { ReactComponent as Send } from '../../assets/Send.svg';
import { ReactComponent as PdfIcon } from '../../assets/Document.svg'; 

const ChatInput = ({resetForm, replyTo, attachment, commentText, setCommentText, handleFileChange, handleSubmit, triggerFileInput}) => {
    // Extract file type correctly from attachment.file
    const fileType = attachment?.file?.type;
    const isImage = fileType?.startsWith("image");
    const isPdf = fileType === "application/pdf";

    
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ header: 1 }, { header: 2 }, { header: 3 }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"]
        ]
    };

    const placeholder = "";
    const { quill, quillRef } = useQuill({ modules, placeholder });

    // 🔹 Sync Quill with `commentText`
    useEffect(() => {
        if (quill && commentText !== quill.root.innerHTML) {
            quill.clipboard.dangerouslyPasteHTML(commentText);
        }
    }, [commentText, quill]);
    

    useEffect(() => {
        if (quill) {
            quill.on("text-change", () => {
                setCommentText(quill.root.innerHTML);
                console.log(commentText)
            });

            // ✅ Enter to Send, Shift + Enter for New Line
            // quill.root.addEventListener("keydown", handleEnter)
        }
        // return () => {
        //     if (quill) {
        //         quill.root.removeEventListener("keydown", handleEnter);
        //     }
        // };
    }, [quill]);

    return (
        <InputContainer>
            {
                replyTo && (
                <ReplyContainer>
                    <ReplyBox>
                        <CloseButton onClick={resetForm}>&#x2715;</CloseButton>
                        <ReplyHeader>
                            <ReplyName>{replyTo?.sender?.username || replyTo?.sender?.name}</ReplyName>
                            <ReplyDate>{moment(replyTo.date).format('MMM DD, YYYY h:mm A')}</ReplyDate>
                        </ReplyHeader>
                        <ReplyText dangerouslySetInnerHTML={{ __html:replyTo.content}}></ReplyText>
                    </ReplyBox>
                </ReplyContainer>
                )
            }
            <CommentInputContainer>
                {attachment?.url && (
                    isImage ? (
                        <AttachmentPreview src={attachment.url} alt="Uploaded Image" />
                    ) : isPdf ? (
                        <PdfAttachmentPreview href={attachment.url} target="_blank">
                            <PdfIcon />
                            <span>PDF Attachment</span>
                        </PdfAttachmentPreview>
                    ) : null
                )}
                {/* <Input
                    type="text"
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                    placeholder="Add a note or an attachment"
                    onKeyDown={handleEnter}
                /> */}
                <div style={{ width: "100%",  borderRadius: "6px" }}>
                    <div ref={quillRef} />
                </div>
                <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*,application/pdf"
                />

                <IconDiv onClick={triggerFileInput}><Attach /></IconDiv>
                <IconDiv onClick={handleSubmit}><Send /></IconDiv>
            </CommentInputContainer>
        </InputContainer>
    )
}

export default ChatInput