import React, {useState} from "react";

import {
    Canvas,
    AgentName,
    IconDiv,
    AgentCard,
    IconButton,
    ApplicantDetails,
    GuarantorDetails,
    EmailName
} from './basic-info.styles'

import {
    Heading,
    TeammateBox,
    TeammateContainer,
    TeammateName,
    FlexDivColumn,
    SubscriptText
} from '../../shared-styles'

import { ReactComponent as Message } from '../../../../assets/Message.svg'
import SendMessageModal from "../../../message-modality/message-modality";


const BasicInfo = ({ applicants, agent, setFocusTab}) => {
    const [tenantsToSend, setTenantsToSend] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open modality for messaging one
    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsModalOpen(true);
    };

    // Function to close modality for messaging
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const combinedApplicants = applicants.flatMap(applicant => 
        applicant.cosigner ? [applicant, applicant.cosigner] : [applicant]
    );

    // console.log(applicants)

    return  (
        <Canvas>
            <FlexDivColumn>
                <Heading>Agent Info</Heading>
                <AgentCard>
                    <div style = {{display:'flex', flexDirection:'column', gap:'3px'}}>
                        <AgentName>{agent.name}</AgentName>
                        <EmailName><strong>Email:</strong> {agent.email}</EmailName>
                        <EmailName><strong>Phone:</strong> {agent.phone}</EmailName> 
                        <SubscriptText><strong>Brokerage:</strong> {agent.brokerage}</SubscriptText>
                    </div>
                    <IconButton onClick={() => setFocusTab('Chat')}>
                        <Message/>
                    </IconButton>
                </AgentCard>
            </FlexDivColumn>
            <FlexDivColumn>
                <Heading>Applicant Info</Heading>
                <TeammateBox style={{padding:'0px 15px'}}>
                {
                    applicants.map(applicant => 
                        <TeammateContainer id = {applicant.id}>                        
                            <ApplicantDetails>
                                <div>
                                    <TeammateName>{applicant.name}</TeammateName>
                                    <EmailName>{applicant.email}</EmailName>
                                    <SubscriptText>{applicant.applicantType}</SubscriptText>
                                </div>
                                <IconDiv onClick={() => handleOpenModalForOne(applicant)}>
                                    <IconButton>
                                        <Message/>
                                    </IconButton>
                                </IconDiv>
                            </ApplicantDetails>
                            {
                                applicant.hasCosigner &&
                                <GuarantorDetails>
                                    <div>
                                        <TeammateName>{applicant.cosigner.name}</TeammateName>
                                        <EmailName>{applicant.cosigner.email}</EmailName>
                                        <SubscriptText>Guarantor</SubscriptText>
                                    </div>
                                    <IconDiv>
                                        <IconButton onClick={() => handleOpenModalForOne(applicant.cosigner)}>
                                            <Message/>
                                        </IconButton>
                                    </IconDiv>
                                </GuarantorDetails>
                            }
                        </TeammateContainer>
                    )
                }
                </TeammateBox>
            </FlexDivColumn>
            <SendMessageModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={combinedApplicants}
                setTenantsToSend={setTenantsToSend}
            />
        </Canvas>
    )
}

export default BasicInfo