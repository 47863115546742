import styled, { css } from "styled-components";
import { FaCircle } from 'react-icons/fa'; // This is a filled circle that can act as a bullet


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    // justify-content: center; /* Center the children */
    align-items: flex-start; /* Align items at the start vertically */
    height: -webkit-fill-available;
    gap: 10px;
    width: -webkit-fill-available;
    // margin: 0px 0px 20px 0px;
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items at the start vertically */
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    position: relative; /* To position CanvasSmall absolutely */
    justify-content: center; /* Center the children */
    overflow: auto;
    padding: 10px;
    margin: 0px 0px 10px 0px;
`;

const CanvasStyles = css`
    border-radius: 10px;
    background: var(--White, #FFF);
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    height: -webkit-fill-available;
    transition: all 0.5s ease; /* Smooth transition */
`;

export const CanvasSmall = styled.div`
    ${CanvasStyles}
    width: 27%;
    padding: 20px;
    min-width: 250px;
    position: absolute;
    margin: 10px;
    overflow: auto;
    right: 0;
    top: 0;
    transform: ${({ showSmallCanvas }) => showSmallCanvas ? 'translateX(0)' : 'translateX(100%)'};
    visibility: ${({ showSmallCanvas }) => showSmallCanvas ? 'flex' : 'hidden'};
    transition: visibility 0.5s ease, transform 0.5s ease; /* Transition for sliding in */
`;


export const Canvas = styled.div`
    ${CanvasStyles}
    width: ${({ showSmallCanvas }) => showSmallCanvas ? '68%' : '70%'}; /* Adjust width based on CanvasSmall visibility */
    transform: ${({ showSmallCanvas }) => showSmallCanvas ? 'translateX(-23%)' : 'translateX(0%)'};
    min-width: 700px;
    padding: 10px 0px 0px;
    transition: width 0.5s ease, transform 0.5s ease;
    overflow: auto;
`;

export const TabContentHeader = styled.div`
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #eaeaea;
    align-items: center;
    width: -webkit-fill-available;
`;


export const TabHolder = styled.div`
    gap: 5px;
    display: flex;
    gap: 40px;
`;

export const Tab = styled.button`
    background: none;
    border: none;
    padding: 10px 5px;
    cursor: pointer;
    width: fit-content;
    border-bottom: 2px solid transparent; // Indicate active tab
    color: var(--Grey, #747474);
    display: flex;
    gap: 3px;
    align-items: center;

    ${({ active }) => active && `
        color: #064274;
        border-color: #064274;
        font-weight: 600;
    `}

    span {
        padding: 3px;
        height: 15px;
        width: 15px;
        font-size: 10px;
        border-radius: 30px;
        background: ${({ active }) => active ? `var(--Light-Blue, #F0F8FF)`:`#F1F1F1`};
        display: flex;
        justify-content: center;
        align-items: center;
    }

`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;

const badgeColors = {
    "Pending": `
        background-color: #ffd1d1; /* Light Red */
        color: #d8000c; /* Dark Red */
    `,
    "Pending Payments": `
        background-color: #fff4e5; /* Soft Amber */
        color: #ffa500; /* Amber */
    `,
    "Form Signing": `
        background-color: #e6f7ff; /* Light Blue */
        color: #007acc; /* Deep Blue */
    `,
    "Re-listed": `
        background-color: #f0f4ff; /* Soft Blue */
        color: #3366cc; /* Medium Blue */
    `,
    "Partially Accepted": `
        background-color: #fff4e5; /* Soft Amber */
        color: #ffa500; /* Amber */
    `,
    "Denied": `
        background-color: #ffebe6; /* Pale Red */
        color: #990000; /* Burgundy */
    `,
    "Awaiting Responses": `
        background-color: #FFFCE5; /* Pale Yellow */
        color: #CC9900; /* Golden Yellow */
    `,
    "Accepted": `
        background-color: #d4edda; /* Soft Green */
        color: #155724; /* Dark Green */
    `,
    "Renewed": `
        background-color: #e3ffe6; /* Completed Green */
        color: #1ccb00; /* Bright Green */
    `,
    "Replacement Requested": `
        background-color: #fce5ff; /* Soft Purple */
        color: #800080; /* Deep Purple */
    `,
    "Replacement Applied": `
        background-color: #e0f7fa; /* Light Aqua */
        color: #00796b; /* Teal Green */
    `,
};


export const StatusBadge = styled.div`
    ${({ status }) => badgeColors[status] || ""}
    padding: 5px 10px;
    width: fit-content;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;


export const NavigationButton = styled.button`
    cursor: pointer;
    background: #f7f7f7;
    border: none;
    width: 35px;
    height: 35px;
    padding: 8px;
    &:hover {
        background-color: #f5f5f5; // A subtle hover effect
    }
    border-radius: 10px;
`;

export const SpaceDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
    padding: 10px 20px;
    border-bottom: 1px solid #F1F1F1;
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;


export const TextNav = styled.span`
    color: var(--Light-Grey, #A4A4A4);
    leading-trim: both;
    text-edge: cap;

    /* Label/Small */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    span {
        color: var(--Greyish-Black, #323232);
    }
`;

export const FilterBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: -webkit-fill-available;
`;

export const ActionButton = styled.button`
    background-color: #064274    ;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 14px;

    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }

`;


export const RemindButton = styled(ActionButton)`
    background-color: #F7F7F7;
    color: #323232;
    
    &:hover{ 
        background-color: #dedede80;
        transition: background-color 0.2s;
    }
`;

export const RelistButton = styled(ActionButton)`
    background-color: #E45A5C;
    color: #fff;
    
    &:hover{ 
        background-color: #B5373A; 
        transition: background-color 0.2s;
    }
`;

export const ButtonDiv = styled.div`
    width: 200px;
`;