import React, { useEffect, useState, useContext, useRef } from "react";
import PropertyTable from "../../components/property-list/property-list";
import PropertyModal from "../../components/property-modality/property-modality";
import { toast } from "react-toastify";
import { ApiContext } from "../../context/apiContext";
import { ContentSection } from "./properties.pages.styles";

const PAGE_SIZE = 20;

const Properties = ({ headerHeight }) => {
  const api = useContext(ApiContext);
  const isFirstRender = useRef(true);

  // States for data, pagination, sorting, and filtering
  const [properties, setProperties] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [forceFetch, setForceFetch] = useState(false);
  // Unsorted state is represented by empty strings
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const propertyId = window.location.pathname.split("/").filter(Boolean)[1];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [focusPropertyId, setFocusPropertyId] = useState();
  const [activeTab, setActiveTab] = useState("info");
  const [total, setTotal] = useState();
  const [previousPage, setPreviousPage] = useState();
  const [previousConfig, setPreviousConfig] = useState();

  // API Call to fetch paginated property data
  const fetchProperties = async (pageNumber) => {
    try {
      if( pageNumber > Math.ceil(total/PAGE_SIZE)){
        return 
      }
      if(pageNumber === previousPage && sortConfig === previousConfig ){
        return
      }
      let apiURL = `/api/listing/internal/all?page=${pageNumber}&pageSize=${PAGE_SIZE}`;
      if (sortConfig.key !== "") {
        apiURL += `&sortKey=${sortConfig.key}&sortDirection=${sortConfig.direction}`;
      }
      const response = await api.get(apiURL);
      console.log("📌 API Response:", response);
      if (pageNumber === 1) {
        setProperties(response.results);
      } else {
        setProperties((prev) => {
          const existingIds = new Set(prev.map(p => p.propertyInfo.id));
          const newData = response.results.filter(p => !existingIds.has(p.propertyInfo.id));
          return [...prev, ...newData];
        });
      }

      setTotal(response.count)
      setPreviousPage(pageNumber)
      setPreviousConfig(sortConfig)
      
      console.log("Next Page URL:", response.next);
      setHasMore(Boolean(response.next));
    } catch (error) {
      toast.error(`Error loading data: ${error.message}`);
      setHasMore(false);
    } finally {
      setIsLoading(false);
      if (forceFetch) setForceFetch(false);
    }
  };

  // Handle modal opening
  const handlePropertyClick = (id) => {
    setIsModalOpen(true);
    setFocusPropertyId(id);
    window.history.pushState({}, "", `/properties/${id}`);
  };

  // Handle modal closing
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFocusPropertyId();
    window.history.pushState({}, "", "/properties");
  };

  // Initial fetch on mount
  useEffect(() => {
    fetchProperties(1);
  }, []);

  // Open modal if propertyId exists in URL
  useEffect(() => {
    if (propertyId) {
      handlePropertyClick(propertyId);
    }
  }, [propertyId]);

  // When sorting changes, reset to page 1 and fetch new data
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setPage(1);
    setProperties([]);
    setForceFetch(true);
    fetchProperties(1);
  }, [sortConfig]);

  // When page changes, fetch additional data if available
  useEffect(() => {
    if ((page > 1 || forceFetch) && hasMore) {
      console.log("📌 Fetching page:", page);
      fetchProperties(page);
    }
  }, [page, forceFetch, hasMore]);

  const loadMore = () => {
    if (hasMore) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <ContentSection headerHeight={headerHeight}>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <PropertyTable
            properties={properties}
            sortConfig={sortConfig}
            setSortConfig={setSortConfig}
            handlePropertyClick={handlePropertyClick}
            setActiveTab={setActiveTab}
            loadMore={loadMore}
          />
        )}
      </ContentSection>

      {isModalOpen && (
        <PropertyModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          focusPropertyId={focusPropertyId}
          setFocusPropertyId={setFocusPropertyId}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeListingIds={properties.map(
            (property) => property.propertyInfo.id
          )}
        />
      )}
    </>
  );
};

export default Properties;
