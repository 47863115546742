import React, { useState, useEffect, useRef, useContext } from "react";
import ChatInput from "../../../chat-input/chat-input";
import moment from 'moment';
import { ApiContext } from "../../../../context/apiContext";
import { SocketContext } from "../../../../context/socketContext";
import { UserContext } from "../../../../context/userContext";

import {
    CommentContainer, CommentHeader, UserName, CommentDate, ReplyButton,  CommentText, Container, CommentsList, FlexDiv, ReplyHeader, ReplyName,
    ReplyText, AttachmentPreview, ParentCommentContainer, ReplySentBox, TextDiv, ImageTextDiv, ArrowDiv, ArrowListContainer, LoadingDiv
} from './internal-notes.styles';

import { ReactComponent as Reply } from '../../../../assets/reply.svg';

const InternalNotes = ({ groupId }) => {
    const { subscribeToNewMessages, socket} = useContext(SocketContext);
    const { user } = useContext(UserContext);
    const api = useContext(ApiContext)
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyTo, setReplyTo] = useState(null);
    const [attachment, setAttachment] = useState({
        file: null,
        url: '',
    });
    const [nextPageUrl, setNextPageUrl] = useState(null); // Store the next page URL

    const commentsListRef = useRef(null); // Ref for the comments list container
    const commentsEndRef = useRef(null); // Ref for the comments list container
    const scrollToBottomRef = useRef(null); // Ref for the comments list container
    const commentRefs = useRef(new Map()).current;
    const loadingRef = useRef(null);

    const fetchNotes = async (url = null) => {
        const urlFetch = url || `/api/chat/history?groupId=${groupId}&member_id=${user?.id}&member_type=USER`;
        loadingRef.current = url ? true: false;
        try {
            const data = await api.get(urlFetch);
            // console.log(data.results)
            const sortedComments = data.results.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            setComments(prev => url ? [...sortedComments, ...prev] : sortedComments); // Adjust based on your API response structure
            setNextPageUrl(data.next);
        } catch (error) {
            console.error('Error fetching chat messages:', error);
            // Handle the error as needed (e.g., show an error message to the user)
        }
    };


    useEffect(() => {
        setComments([]);
        setNextPageUrl(null);
        scrollToBottomRef.current.style.display = 'none';
        fetchNotes();
    }, [groupId]);

    useEffect(() => {
        const unsubscribe = subscribeToNewMessages((newMessage) => {
            if (newMessage.groupId === groupId) {
                // Assuming newMessage is structured appropriately for your comments
                setComments((prevComments) => [...prevComments, newMessage]);
            }
        });
        return () => unsubscribe();
    }, [subscribeToNewMessages, groupId]);

    useEffect(() => {
        if(loadingRef.current){
            scrollToOffset();
            loadingRef.current = false
        }else{
            scrollToBottom();
        }
    }, [comments, loadingRef]); // Depen

    const scrollToBottom = () => {
        if (commentsEndRef.current) {
            commentsEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const scrollToOffset = () => {
        if (commentsListRef.current) {
            // Scrolls 50px up from the current scroll position
            commentsListRef.current.scrollTop = commentsListRef.current.clientHeight + 200 ;
        }
    };


    const handleEnter = (e) => {
        if (e.key === 13 && !e.shiftKey) {
            handleSubmit(e);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (commentText.trim() === "<p><br></p>") return; 
        const message = {
            message: commentText,
            group_id: groupId,
            parent: replyTo? replyTo.id:null,
        };

        console.log(message)

        if(!attachment.file && commentText === '') return
        if (attachment.file) {
            const formData = new FormData();
            formData.append('file', attachment.file); 

            try {
                const data = await api.post(`/api/chat/upload_file/`, formData);
                socket.emit('send_message', {
                    ...message,
                    file_url: data.file
                })
            }catch (error) {
                console.error('Failed to upload file:', error);
            }
        } else {
            socket.emit('send_message', message);
        }
        resetForm();
    };

    const addToReply = (comment) => {
        const isAtBottom = isUserAtBottom();
        setReplyTo(comment);
        if (isAtBottom){
            scrollToBottom();
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setAttachment({
                file: file,
                url: fileUrl
            });
        }
    };

    const resetForm = () => {
        setCommentText('');
        setReplyTo(null);
        setAttachment({ file: null, url: '' });
    };

    const isUserAtBottom = () => {
        if (!commentsListRef.current) return false;
    
        const { scrollTop, clientHeight, scrollHeight } = commentsListRef.current;
        // Check if the user is at the bottom of the comments list
        // This is true if the scrollTop (how far the user has scrolled) plus the clientHeight (the visible height of the element)
        // is equal to the scrollHeight (the total height of the content), with a small tolerance to account for fractional pixel values and rendering inconsistencies.
        const bottomTolerance = 5; // pixels
        return scrollTop + clientHeight + bottomTolerance >= scrollHeight;
    };
    
    const handleScroll = () => {
        // Logic to show/hide the scrollToBottomRef based on scroll position
        const isAtBottom = isUserAtBottom();
        scrollToBottomRef.current.style.display = isAtBottom ? 'none' : 'flex';

        if (commentsListRef.current.scrollTop === 0 && nextPageUrl) {
            fetchNotes(nextPageUrl); // Load more messages when scrolled to the top and there are more messages to load
        }
    };

    const scrollToComment = (parentId) => {
        // Scroll the parent comment into view
        const parentRef = commentRefs.get(parentId.toString());
        if (parentRef) {
            parentRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }else {
            console.warn(`No ref found for comment ID: ${parentId}`);
        }
    };


    return (
        <Container>
            {/* <div style = {{position:'relative'}}> */}
            <ArrowListContainer>
                <CommentsList ref={commentsListRef}  onScroll={handleScroll}>
                    {
                        nextPageUrl &&
                        <LoadingDiv>Loading</LoadingDiv>
                    }
                    {
                        comments.map(comment => (
                        <CommentContainer 
                            key={comment.id} 
                            ref={(el) => {
                                if (el) {
                                    commentRefs.set(comment.id.toString(), el);
                                }
                            }}
                          >
                            <FlexDiv>
                                <CommentHeader>
                                    <UserName>{comment.sender.username || comment.sender.name}</UserName>
                                    <CommentDate>
                                        {
                                            moment(comment.createdAt).format('MMM DD, YYYY') ===  moment(new Date()).format('MMM DD, YYYY')?
                                            moment(comment.createdAt).format('MMM DD, YYYY h:mm A')
                                            :
                                            `Today ${moment(comment.createdAt).format('h:mm A')}`
                                        }
                                    </CommentDate>
                                </CommentHeader>
                                <ReplyButton onClick={() => addToReply(comment)}>Reply <Reply /></ReplyButton>
                            </FlexDiv>
                            <ImageTextDiv>
                                {
                                    comment.fileUrl && 
                                    <AttachmentPreview src={comment.fileUrl} onClick={() =>  window.open(comment.fileUrl, '_blank').focus()} />
                                }
                                <TextDiv>
                                    {
                                        comment.parent && (
                                        <ParentCommentContainer onClick={() => scrollToComment(comment.parent.id)}>
                                            <ReplySentBox>
                                                <ReplyHeader>
                                                    <ReplyName>{comment.parent.sender.username || comment.parent.sender.name}</ReplyName>
                                                    {/* <ReplyDate>{moment(comment.parent.date).format('MMM DD, YYYY h:mm A')}</ReplyDate> */}
                                                </ReplyHeader>
                                                <ReplyText dangerouslySetInnerHTML={{ __html: comment.parent.content }}></ReplyText>
                                            </ReplySentBox>
                                        </ParentCommentContainer>
                                        )
                                    }
                                    <CommentText dangerouslySetInnerHTML={{ __html: comment.content }}></CommentText>
                                </TextDiv>
                            </ImageTextDiv>
                        </CommentContainer>
                        ))
                    }
                    <div ref={commentsEndRef} />
                </CommentsList>
                <ArrowDiv ref={scrollToBottomRef} onClick={scrollToBottom}>
                    ↓
                </ArrowDiv>
            </ArrowListContainer>
            <ChatInput
                resetForm = {resetForm} 
                replyTo = {replyTo} 
                attachment = {attachment} 
                commentText = {commentText} 
                setCommentText = {setCommentText} 
                // handleEnter = {handleEnter} 
                handleFileChange = {handleFileChange} 
                handleSubmit = {handleSubmit}
                triggerFileInput={triggerFileInput}
            />
        </Container>
    );
};

export default InternalNotes
