import styled from "styled-components";

export const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px 0px 180px;
    // align-items: center;
    background: #E5E5E5;
    width: 100vw;
    // gap: 10px;
    height: -webkit-fill-available;
    overflow: auto;
    margin: auto;

    @media screen and (max-width: 800px) {
        padding: 20px 10px 20px 10px;
    }
`;


export const FormTextArea = styled.textarea`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  height: 100px;
  font-family: Avenir;
`;

export const InnerSection = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 10px;
    // height: -webkit-fill-available;
    // overflow: auto;
    margin: auto;
`;


export const Section = styled.div`
    display: flex;
    padding: 25px;
    flex-direction: column;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 10px;
    gap: 15px;

    @media screen and (max-width: 1100px) {
        padding: 15px;
        gap: 10px;
    }
`;

export const Title = styled.div`
    color: #333;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: 600px){
        font-size: 14px;
    }

`;

export const RowContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`
export const SubmitButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const CancelButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #E45A5C;
    color: white;
    &:hover{ 
        background-color: #E45A5C70;
        transition: background-color 0.2s;
    }
`;