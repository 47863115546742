import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PropertyInfo from "../../components/property-info/property-info";
import FormInput from "../../components/form-input/form-input";
import moment from "moment";
import {
    Canvas, Title, Section, Heading, CancelButton, SubmitButton, RowContainer, InnerSection, FlexDiv, DropdownDiv, Dropdown, ModalContent, ModalOverlay
} from './renewal-replacement.pages.styles';

const ReplacementRequest = () => {
    const { renewalId, tenantId } = useParams();

    const [renewalData, setRenewalData] = useState();
    const [errors, setErrors] = useState({});
    const [isFocus, setIsFocus] = useState('Fetching');
    // const [replacementCount, setReplacementCount] = useState(1); // Number of replacements
    const [newApplicants, setNewApplicants] = useState([]); // Array of new applicant objects
    const [showConfirmPopup, setShowConfirmPopup] = useState(false); // State for popup visibility
    const [confirmAction, setConfirmAction] = useState(null); // Function to confirm
    const [confirmText, setConfirmText] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response = await fetch(`/api/renewal/tenant-detail?tenantId=${tenantId}&renewalId=${renewalId}`);
                if (response.ok) {                    
                    let data = await response.json();
                    console.log(data, data.renewalStatus === "Replacement Requested" && data.totalApplicants === 0);
                    if(data.renewalStatus === "Replacement Requested" && data.totalApplicants === 0){
                        setIsFocus('Initial')
                        // console.log("set is focus")
                    }else if (data.renewalStatus === "Accepted" || data.renewalStatus === "Denied"){
                        setIsFocus('Submit')
                    }
                    // console.log(data);
                    setRenewalData(data);
                     
                } else {
                    // Handle errors, e.g., response status not OK
                    throw Error("Failed to fetch properties:", response.status);
                }
            } catch (error) {
                // Handle network errors or other fetch issues
                console.error("Failed to fetch properties:", error);
            }
            // const data = {
            //     renewalId: 1,
            //     property: {
            //         address: "47 Englewood Ave. #6 Brighton",
            //         moveOut: "2025-03-03",
            //         imageUrl: "https://ygl-photos.s3.us-west-004.backblazeb2.com/340W66fc1c0334938.jpg",
            //         price: 3175.0,
            //     },
            //     renewalStatus: "Replacement Requested",
            //     totalTenants: 2,
            //     totalApplicants: 0,
            //     latestUpdateDate: "2025-01-14T04:28:42.867052Z",
            //     deadline: "2025-01-13",
            //     latestOfferPrice: 3175.0,
            //     tenants: [
            //         {
            //             tenantId: 1,
            //             name: "new",
            //             email: "roombae3@gmail.com",
            //             type: "Student",
            //             phone: null,
            //             renewalFormStatus: "Pending",
            //             renewalStatus: "Accepted",
            //             leaseStatus: "Pending",
            //             renewalForm: "None",
            //         },
            //         {
            //             tenantId: 2,
            //             name: "alex",
            //             email: "roombae3@gmail.com",
            //             type: "Student",
            //             phone: null,
            //             renewalFormStatus: "Pending",
            //             renewalStatus: "Declined",
            //             leaseStatus: "Pending",
            //             renewalForm: "None",
            //         },
            //         {
            //             tenantId: 3,
            //             name: "stavya",
            //             email: "roombae3@gmail.com",
            //             type: "Student",
            //             phone: null,
            //             renewalFormStatus: "Pending",
            //             renewalStatus: "Declined",
            //             leaseStatus: "Pending",
            //             renewalForm: "None",
            //         },
            //         {
            //             tenantId: 4,
            //             name: "alex",
            //             email: "roombae3@gmail.com",
            //             type: "Student",
            //             phone: null,
            //             renewalFormStatus: "Pending",
            //             renewalStatus: "Declined",
            //             leaseStatus: "Pending",
            //             renewalForm: "None",
            //         },
            //         {
            //             tenantId: 5,
            //             name: "stavya",
            //             email: "roombae3@gmail.com",
            //             type: "Student",
            //             phone: null,
            //             renewalFormStatus: "Pending",
            //             renewalStatus: "Declined",
            //             leaseStatus: "Pending",
            //             renewalForm: "None",
            //         },
            //     ],
            // };
            // if (data.renewalStatus === "Replacement Requested" && data.totalApplicants === 0) {
            //     setIsFocus('Submit');
            // }
            // setRenewalData(data);
        };
        fetchData();
    }, [tenantId, renewalId]);

    const handlePopupConfirm = () => {
        if (confirmAction) {
            confirmAction();
            setShowConfirmPopup(false);
        }
    };

    const handlePopupCancel = () => {
        setShowConfirmPopup(false);
        setConfirmAction(null);
    };

    const confirmAndExecute = (action, text) => {
        setShowConfirmPopup(true);
        setConfirmText(text);
        setConfirmAction(() => action);
    };

    const relist = async () => {
        try {
            // Build the request payload
            const requestData = {
                renewalId: renewalId,
                status: "relist",
                tenantId: tenantId,
            };
    
            // Make the API call using fetch
            const response = await fetch("/api/renewal/tenant-response", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
    
            // Check if the response is successful
            if (response.ok) {
                const responseData = await response.json(); // Parse the JSON response
                setIsFocus('Submit')
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            // Handle errors
            toast.error(`Failed to update tenant response: ${error.message}`);
            console.error("API Error:", error);
        }
    };

    const continueAsIs = async () => {
        try{
// Build the request payload
            const requestData = {
                renewalId: renewalId,
                tenantId: tenantId,
                status: "continue-without-replacement"
            };
    
            // Make the API call using fetch
            const response = await fetch("/api/renewal/tenant-response", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });
    
            // Check if the response is successful
            if (response.ok) {
                const responseData = await response.json(); // Parse the JSON response
                setIsFocus('Submit')
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            // Handle errors
            toast.error(`Failed to update tenant response: ${error.message}`);
            console.error("API Error:", error);
        }
    }


    const handleReplacementCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        // setReplacementCount(count);
        setNewApplicants(Array(count).fill({ name: '', email: '', phone: '', applicantType: '' }));
    };

    const handleApplicantChange = (index, field, value) => {
        setNewApplicants((prev) => {
            const updatedApplicants = [...prev];
            updatedApplicants[index] = { ...updatedApplicants[index], [field]: value };
            return updatedApplicants;
        });
    };

    const validateApplicants = () => {
        const newErrors = [];
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        newApplicants.forEach((applicant, index) => {
            const applicantErrors = {};
            if (!applicant.name) applicantErrors.name = "Name is required";
            if (!applicant.email) {
                applicantErrors.email = "Email is required";
            } else if (!emailRegex.test(applicant.email)) {
                applicantErrors.email = "Enter a valid email address";
            }
            if (!applicant.applicantType) applicantErrors.applicantType = "Type is required";

            newErrors[index] = applicantErrors;
        });

        setErrors(newErrors);

        return newErrors.every((error) => Object.keys(error).length === 0);
    };

    const submitReplacement = async () => {
        if (!validateApplicants()) return;

        try {
            const requestData = {
                renewalId: renewalId,
                tenantId: tenantId,
                status: "replacement",
                applicants: newApplicants, // Send the array of applicants
            };

            const response = await fetch("/api/renewal/tenant-response", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setIsFocus('Submit');
            } else {
                throw new Error(`Unexpected response status: ${response.status}`);
            }
        } catch (error) {
            toast.error(`Failed to update tenant response: ${error.message}`);
        }
    };

    return (
        <Canvas>
        {
            showConfirmPopup && (
                <ModalOverlay>
                    <ModalContent>
                        <Title>Confirmation</Title>
                        <Heading>Are you sure you 
                            {
                                confirmText === "Relist"? 
                                " do not want to proceed with the renewal?"
                                :
                                " want to continue without replacement of declined tenants? The remaining tenants will be liable to pay the complete rent." 
                            }
                        </Heading>
                        <p>This action cannot be changed.</p>
                        <RowContainer>
                            <SubmitButton onClick={handlePopupConfirm}>Yes</SubmitButton>
                            <CancelButton onClick={handlePopupCancel}>No</CancelButton>
                        </RowContainer>
                    </ModalContent>
                </ModalOverlay>
            )}
        {
            isFocus === "Submit" ? 
            <Title style={{ margin: 'auto' }}>Thank you for your selection 
                {(renewalData?.renewalStatus === "Accepted" || renewalData?.renewalStatus === "Denied") && `- Tenant ${renewalData?.renewalStatus} Renewal`}!
            </Title>
            : 
            isFocus === "Initial"?
            <InnerSection>
                <Section>
                    <Title>Replacement Form</Title>
                    <PropertyInfo
                        property={renewalData?.property}
                        offerPrice={renewalData?.latestOfferPrice}
                    />
                </Section>
                <Section>
                    <Heading>
                        It seems that { renewalData?.tenants.filter(tenant => tenant.renewalStatus === "Denied").map(tenant => tenant.name).join(", ") } do not want to continue with the lease. 
                        Please select alternative steps available to continue renting or we will re-list the property by {moment(renewalData.deadline).format('MMM DD, YYYY')}.
                    </Heading>
                    
                </Section>
                <RowContainer>
                    <CancelButton onClick={() => confirmAndExecute(relist, "Relist")}>Relist Property now</CancelButton>
                    <SubmitButton onClick={() => setIsFocus('Form')}>Offer Replacement</SubmitButton>
                    <SubmitButton onClick={() => confirmAndExecute(continueAsIs, "Continue")}>Continue to renewal without replacement</SubmitButton>
                </RowContainer>
            </InnerSection>
            :
            isFocus === "Form"?
            <InnerSection>
                <Section>
                    <Title>Replacement Form</Title>
                    <PropertyInfo
                        property={renewalData?.property}
                        offerPrice={renewalData?.latestOfferPrice}
                    />
                </Section>
                <Section style={{overflow: "auto", height: "-webkit-fill-available"}}>
                    <Heading>
                        Select the number of replacements you want to make.
                    </Heading>
                    <DropdownDiv>
                        <Dropdown onChange={handleReplacementCountChange}>
                            <option value={0} disabled selected>Select number of replacements</option>
                            {[...Array(renewalData.tenants.filter(tenant => tenant.renewalStatus === "Denied").length)].map((_, idx) => (
                                <option value={idx + 1}>{idx + 1}</option>
                            ))}
                        </Dropdown>
                    </DropdownDiv>
                    <div style={{display:'flex', flexDirection:'column', gap: '25px'}}>
                    {newApplicants.map((applicant, index) => (
                        <div style={{display:'flex', flexDirection:'column', gap: '10px'}}>
                            <Heading>Replacement #{index + 1}</Heading>
                            <FlexDiv style ={{gap: '10px'}} key={index}>
                                <FormInput
                                    label="Name"
                                    type="text"
                                    isRequired = {true}
                                    defaultValue={applicant.name}
                                    flexBasis={true}
                                    handleChange={(value) => handleApplicantChange(index, 'name', value)}
                                    error={errors[index]?.name}
                                />
                                <FormInput
                                    label="Email"
                                    type="text"
                                    isRequired = {true}
                                    defaultValue={applicant.email}
                                    flexBasis={true}
                                    handleChange={(value) => handleApplicantChange(index, 'email', value)}
                                    error={errors[index]?.email}
                                />
                                <FormInput
                                    label="Phone"
                                    type="text"
                                    isRequired = {false}
                                    defaultValue={applicant.phone}
                                    flexBasis={true}
                                    handleChange={(value) => handleApplicantChange(index, 'phone', value)}
                                    error={errors[index]?.phone}
                                />
                                <DropdownDiv>
                                    <FlexDiv>
                                        <div>Applicant Type<span style={{ color: 'red' }}>*</span></div>
                                        {errors[index]?.applicantType && <span style={{ color: 'red' }}>{errors[index]?.applicantType}</span>}
                                    </FlexDiv>
                                    <Dropdown
                                        onChange={(e) => handleApplicantChange(index, 'applicantType', e.target.value)}
                                        value={applicant.applicantType || ""}
                                    >
                                        <option value="" disabled>Select Applicant Type</option>
                                        <option value="International Student">International Student</option>
                                        <option value="Student">Student</option>
                                        <option value="Working Professional">Working Professional</option>
                                        <option value="Family">Family</option>
                                    </Dropdown>
                                </DropdownDiv>
                            </FlexDiv>
                        </div>
                        
                    ))}
                    </div>
                </Section>
                <RowContainer>
                    <SubmitButton onClick={submitReplacement}>Submit</SubmitButton>
                </RowContainer>
            </InnerSection>
            :
            <Title style={{ margin: 'auto' }}>Fetching Information...</Title>
        }
        </Canvas>
    );
};

export default ReplacementRequest;



