import styled from "styled-components";

export const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px 20px 180px;
    // align-items: center;
    background: #E5E5E5;
    width: 100vw;
    // gap: 10px;
    height: -webkit-fill-available;
    // overflow: hidden;
    margin: auto;

    @media screen and (max-width: 800px) {
        padding: 20px 10px 20px 10px;
    }
`;

export const InnerSection = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 10px;
    // height: -webkit-fill-available;
    overflow: auto;
    margin: auto;
`;


export const Section = styled.div`
    display: flex;
    padding: 25px;
    flex-direction: column;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 10px;
    gap: 15px;

    @media screen and (max-width: 1100px) {
        padding: 15px;
        gap: 10px;
    }
`;

export const Title = styled.div`
    color: #333;
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // @media screen and (max-width: 600px){
    //     font-size: 14px;
    // }

`;

export const RowContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`
export const SubmitButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const CancelButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #E45A5C;
    color: white;
    &:hover{ 
        background-color:#B5373A; 
        transition: background-color 0.2s;
    }
`;

export const DropdownDiv = styled.div`
    color: #2C2C2C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 250px;
    // max-width: 450px;
    width: -webkit-fill-available;
    flex-basis: 250px;
    flex: 1;
`;


export const Dropdown = styled.select`
    display: flex;
    padding: var(--12, 12px);
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid var(--F1, #F1F1F1);
    color: ${props => props.value === "" && 'grey'}


`;

export const FlexDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;


export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

export const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
`;