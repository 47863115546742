import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom/client'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { makeServer } from './dev-server/mirageServer';
import ContextProvider from './context/context';
import { BrowserRouter } from "react-router-dom";


// Check if we're in development mode before starting Mirage
// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }


// const root = ReactDOM.createRoot(document.getElementById('root'));c
const root = ReactDOM.createRoot(document.getElementById('root'), { 
  hydrate: false 
});

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <App />
      </ContextProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
