import React from 'react'
import moment from 'moment';
import {
    Container,
    Header,
    UserName,
    DateDiv,
    Text,
    AttachmentPreview,
    PdfAttachmentPreview,
    MessageBox
} from './chat-message.styles';

const ChatMessage = ({ message }) => {

    // Extract file extension/type from URL
    const fileUrl = message.fileUrl;
    const isImage = /\.(jpeg|jpg|png|gif)$/i.test(fileUrl);
    const isPdf = fileUrl?.endsWith(".pdf");

    return (
        <MessageBox isYou={message.isYou}>
            <Container isYou={message.isYou}>
                {message.content && <Text isYou={message.isYou} dangerouslySetInnerHTML={{ __html: message.content }}/>}
                
                {/* Render Image or PDF Attachment */}
                {fileUrl && (
                    isImage ? (
                        <AttachmentPreview src={fileUrl} onClick={() => window.open(fileUrl, '_blank').focus()} />
                    ) : isPdf ? (
                        <PdfAttachmentPreview href={fileUrl} target="_blank">
                            {/* <PdfIcon style={{fill: !message.isYou && "#ffffff"}}/> */}
                            <span>PDF Attachment</span>
                        </PdfAttachmentPreview>
                    ) : null
                )}
            </Container>
            
            <Header isYou={message.isYou}>
                <UserName>{message.sender.username || message.sender.name}</UserName>
                <span>-</span>
                <DateDiv>
                    {moment(message.createdAt).format('MMM DD, YYYY') === moment(new Date()).format('MMM DD, YYYY') ?
                        `Today ${moment(message.createdAt).format('h:mm A')}`
                        :
                        moment(message.createdAt).format('MMM DD, YYYY h:mm A')
                    }
                </DateDiv>
            </Header>
        </MessageBox>
    );
};

export default ChatMessage;
