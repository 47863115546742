import React, { useContext, useEffect, useState } from "react";
import PropertyInfo from "../property-info/property-info";
import { ApiContext } from "../../context/apiContext";
import { toast } from 'react-toastify'
import { 
    FilterBarContainer,
    SpaceDiv,
    TextNav,
    NavigationButton,
    FlexDiv,
    SignButton,
    MoreActionsButton,
    ModalActions,
    ModalOverlay,
    ModalContent,
    CancelButton
 } from "./deal-detail-header.styles";

const DealDetailHeader = ({deal, setDeals, filteredDealIds, handleClick}) => {
    const [index, setIndex] = useState(null);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false); // State for confirmation popup
    const api = useContext(ApiContext);
    
    useEffect(() => {
        const index = filteredDealIds?.indexOf(deal.id);
        setIndex(index);
    },[deal, filteredDealIds])

    const sign = async () => {
        try {
            const data = await api.get(`/api/lease/landlord_signature`,{dealId: deal.id})
            toast.success('Reminder successfully sent!');
        }catch(error){
            toast.error(error);
        }
    }

    const handleCancel = async () => {
        try {
            await api.put(`/api/deal/cancel`,{dealId: deal.id})
            toast.success('Deal cancelled!');
        }catch(error){
            toast.error(error);
        } finally {
            setDeals(prev => prev.filter(item => item.id !== deal.id));
            handleClick();
        }
    }

    const openCancelModal = () => {
        setIsCancelModalOpen(true);
    };

    const closeCancelModal = () => {
        setIsCancelModalOpen(false);
    };

    return (
        <>
            <FilterBarContainer>
                <SpaceDiv style = {{borderBottom: '1px solid #F1F1F1'}}>
                    <FlexDiv>
                        <NavigationButton onClick={() => handleClick()}>{"<"}</NavigationButton>
                        <TextNav>Deals/
                            <span>Detail View</span>
                        </TextNav>
                    </FlexDiv>
                    <FlexDiv>
                        {
                            index + 1 !== 1 &&
                            <NavigationButton onClick={() => handleClick(filteredDealIds[index-1])}>{"<"}</NavigationButton>
                        }
                        <TextNav style={{fontSize:'14px'}}><span>{index + 1}/{filteredDealIds?.length}</span></TextNav>
                        {
                            index + 1 !== filteredDealIds.length &&
                            <NavigationButton onClick={() => handleClick(filteredDealIds[index+1])}>{">"}</NavigationButton>
                        }
                    </FlexDiv>
                </SpaceDiv>
                <SpaceDiv>
                    <PropertyInfo
                        property={deal.property}
                        offerPrice={deal.dealPrice}
                    />
                    <SpaceDiv style={{gap:'10px'}}>
                    {
                        deal.paymentStatus === "Completed" && deal.leaseStatus === "Completed" && deal.landlordSignatureStatus === "Pending"  &&
                        <SignButton onClick={sign}>Sign</SignButton>
                    }
                    <MoreActionsButton onClick={openCancelModal}>Cancel Deal</MoreActionsButton>
                    </SpaceDiv>
                </SpaceDiv>
            </FilterBarContainer>
            {
                isCancelModalOpen && 
                <ModalOverlay>
                    <ModalContent>
                        <h4>Are you sure you want to cancel this deal?</h4>
                        <ModalActions>
                            <CancelButton onClick={closeCancelModal}>Cancel</CancelButton>
                            <MoreActionsButton onClick={handleCancel}>
                                Confirm
                            </MoreActionsButton>
                        </ModalActions>
                    </ModalContent>
                </ModalOverlay>
            }
        </>
    )
}

export default DealDetailHeader