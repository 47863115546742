import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';

import {
    ModalBackground,
    ModalContainer,
    ModalHeader,
    ConfirmationMessage,
    ActionButtonsContainer,
    ConfirmButton,
    CancelButton,
    RemarksContainer,
    Remarks
} from './reject-modal.styles'
import { ApiContext } from '../../context/apiContext';

const RejectionModal = ({ onClose, applicationId, updateApplication }) => {

    const [remark, setRemark] = useState('');

    const api = useContext(ApiContext)
    const cancelClick = (event) => {
        event.stopPropagation();
        onClose();
    }

    const confirmRejection = async (event) => {
        event.preventDefault();
    
        try {
            
            const payload = {
                applicationId: applicationId,
                remark: remark,
            };
            const data = await api.put(`/api/application/reject`, payload);
            
            updateApplication(data);
            onClose(); 
        } catch (error) {
            console.error('Error rejecting application', error);
        }
    };

    const handleModalClick = (event) => {
        event.stopPropagation();
    };


    return ReactDOM.createPortal(
        <ModalBackground onClick={cancelClick}>
            <ModalContainer onClick = {handleModalClick}>
                <ModalHeader>Confirm Rejection</ModalHeader>
                <ConfirmationMessage>Are you sure you want to reject this application?</ConfirmationMessage>
                <RemarksContainer>
                    <Remarks
                        id = 'remarks'
                        rows={5} 
                        cols={50}
                        onChange={(event) => setRemark(event.target.value)}
                    />
                </RemarksContainer>
                <ActionButtonsContainer>
                    <CancelButton onClick={cancelClick}>Cancel</CancelButton>
                    <ConfirmButton onClick={confirmRejection}>Confirm</ConfirmButton>
                </ActionButtonsContainer>
            </ModalContainer>
        </ModalBackground>,
        document.getElementById('modal-root')
    );
};

export default RejectionModal;
