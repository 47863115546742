import React, { useContext, useState } from "react";
import ReactDOM from 'react-dom';
import FormInput from "../form-input/form-input";
import PropertyInfo from "../property-info/property-info";
import moment from "moment";
import Loader from '../loader/loader';
import { toast } from "react-toastify";
import { ApiContext } from "../../context/apiContext";
import { ReactComponent as Document } from '../../assets/document-upload.svg'
import {
    ModalOverlay, ModalWrapper, Section, SpaceDiv, FlexDiv, CloseButton, Title, WrapDiv, TextInput, Heading, SubmitButton,
    UploadContainer, FileUploadLabel, HiddenFileInput, RoundDiv, UploadInstruction, AttachmentBox, PreviewContainer, PDFIconWrapper, FileName,
    OpenButton, DeleteButton
} from './renewal-offer-modality.styles'

const RenewalOffer = ({renewalId, renewalProperty, onClose, addOffer, offerPrice, firstOffer = false}) => {
    const api = useContext(ApiContext);
    const [errors, setErrors] = useState({});
    const [previewUrl, setPreviewUrl] = useState(null)
    const [offer, setOffer] = useState({
        title: "",
        description: "",
        updateRent: offerPrice || renewalProperty.price,
        validityDate: "",
        // attachment: null,
    })

    const [dates, setDates] = useState({
        leaseStartDate: '',
        leaseEndDate: '',
    })
    const [isLoading, setIsLoading] = useState(false);

    const updateDates = (key, value) => {
        console.log(key, value)
        setDates(prev => ({
            ...prev,
            [key]: value
        }))
    }


    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];
    //     setOffer(prev => ({
    //         ...prev,
    //         attachment: event.target.files[0]
    //     }))
    //     if (file && file.type.includes('pdf')) {
    //         const objectUrl = URL.createObjectURL(file);
    //         setPreviewUrl(objectUrl);
    //     }else {
    //         toast.error("Only PDF files are supported.");
    //     }
    // };


    // const onRemove = () => {
    //     setPreviewUrl(null)
    //     setOffer(prev => ({...prev, attachment: null}))
    // }

    const updateOffer = (key, value) => {
        setOffer(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const validateInputs = () => {
        const newErrors = {};
        if (!offer.title.trim()) newErrors.title = "Title is required.";
        if (!offer.updateRent || isNaN(offer.updateRent) || parseFloat(offer.updateRent) <= 0)
            newErrors.updateRent = "New Rent is required.";
        if (!offer.validityDate || !moment(offer.validityDate, "YYYY-MM-DD", true).isValid())
            newErrors.validityDate = "Deadline is required.";

        if(firstOffer){
            if(!dates.leaseStartDate || !moment(dates.leaseStartDate, "YYYY-MM-DD", true).isValid())
                newErrors.leaseStartDate = "Date is required.";

            if(!dates.leaseEndDate || !moment(dates.leaseEndDate, "YYYY-MM-DD", true).isValid())
                newErrors.leaseEndDate = "Date is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const submitOffer = async (event) => {
        event.preventDefault();
        if(validateInputs()){
            try{
                setIsLoading(true);
                const formData = new FormData();
                formData.append("renewalId", renewalId);
                formData.append("title", offer.title);
                formData.append("description", offer.description);
                formData.append("updateRent", offer.updateRent);
                formData.append("validityDate", offer.validityDate);
                
                if(firstOffer){
                    formData.append("leaseStartDate", dates.leaseStartDate);
                    formData.append("leaseEndDate", dates.leaseEndDate);
                }
                
                // if (offer.attachment) formData.append("attachment", offer.attachment);
                if(firstOffer) formData.append("deadline", offer.validityDate);

                const endpoint = firstOffer ? "/api/renewal/request" : "/api/renewal/offer";
                const data = await api.post(endpoint, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                addOffer(data);
                onClose();
            }catch(error){
                toast.error("Error submitting offer:", error);
            }finally{
                setIsLoading(false);
            }
        }
    }

    return ReactDOM.createPortal(
        <ModalOverlay onClick={onClose}>
            <ModalWrapper
                onClick={(e) => e.stopPropagation()}
            >
                {
                    isLoading && <Loader />
                }
                <SpaceDiv>
                    <Title>Renewal Offer</Title>
                    <FlexDiv>
                        <span style={{fontSize:"14px"}}>Close</span>
                        <CloseButton onClick={onClose}>X</CloseButton>
                    </FlexDiv>
                </SpaceDiv>
                <Section>
                    {/* <SectionTitle>Property</SectionTitle> */}
                    <PropertyInfo
                        property={renewalProperty}
                        offerPrice={offerPrice || renewalProperty.price}
                    />
                </Section>
                <Section>
                    {/* <SectionTitle>Offer Information</SectionTitle> */}
                    <WrapDiv>
                        <FormInput
                            label = "Offer Title"
                            type = "text"
                            isRequired={true}
                            flexBasis={true}
                            handleChange={(value) => updateOffer("title", value)}
                            error={errors.title}
                        /> 
                    </WrapDiv>
                    <WrapDiv>
                        <FormInput
                            label = "New Rent"
                            type = "number"
                            isRequired={true}
                            flexBasis={true}
                            defaultValue={offer.updateRent}
                            handleChange={(value) => updateOffer("updateRent", value)}
                            error={errors.updateRent}
                        /> 
                        <FormInput
                            label = "Revert Deadline"
                            type = "date"
                            isRequired={true}
                            flexBasis={true}
                            defaultValue={
                                offer?.validityDate? moment.utc(new Date(offer.validityDate)).format("YYYY-MM-DD"): ""
                            }
                            handleChange={(value) => updateOffer( "validityDate", moment(value).format("YYYY-MM-DD"))}
                            error={errors.validityDate}
                        />    
                        { 
                            firstOffer && (
                                <>
                                <FormInput
                                    label = "Lease Start"
                                    type = "date"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={
                                        dates?.leaseStartDate? moment.utc(new Date(dates.leaseStartDate)).format("YYYY-MM-DD"): ""
                                    }
                                    handleChange={(value) => updateDates( "leaseStartDate", moment(value).format("YYYY-MM-DD"))}
                                    error={errors.leaseStartDate}
                                />    
                                <FormInput
                                    label = "Lease End"
                                    type = "date"
                                    isRequired={true}
                                    flexBasis={true}
                                    defaultValue={
                                        dates?.leaseEndDate? moment.utc(new Date(dates.leaseEndDate)).format("YYYY-MM-DD"): ""
                                    }
                                    handleChange={(value) => updateDates( "leaseEndDate", moment(value).format("YYYY-MM-DD"))}
                                    error={errors.leaseEndDate}
                                />    
                                </>
                            )
                        }
                        
                    </WrapDiv>
                </Section>
                <Section style={{gap:"5px"}}>
                    <Heading>Offer Description</Heading>
                    <TextInput
                        rows={3}
                        value={offer.description}
                        onChange={(event) => updateOffer("description", event.target.value)}
                    />
                </Section>
                {/* <Section style={{gap:"5px"}}>
                    <Heading>Attachment (if any)</Heading>
                    <UploadContainer>
                        {
                            previewUrl ?
                            // <AttachmentBox>
                            //     <img src = {previewUrl}/>
                            //     <RemoveButton onClick={onRemove}>×</RemoveButton>
                            // </AttachmentBox>
                             <AttachmentBox>
                                <PreviewContainer>
                                    <PDFIconWrapper>
                                        <Document /> {/* Use a PDF icon or SVG component 
                                    </PDFIconWrapper>
                                    <FileName>{offer.attachment.name}</FileName>
                                    <OpenButton onClick={() => window.open(previewUrl, '_blank')}>
                                        Open
                                    </OpenButton>
                                    <DeleteButton onClick={onRemove}>
                                        Remove
                                    </DeleteButton>
                                </PreviewContainer>
                            </AttachmentBox>
                            :
                            <FileUploadLabel htmlFor="file-upload">
                                <RoundDiv><Document /></RoundDiv>
                                <HiddenFileInput 
                                    id="file-upload" 
                                    type="file" 
                                    accept="application/pdf" 
                                    onChange={handleFileUpload} 
                                />
                                <UploadInstruction>Click to Upload<br/>(Max. File size: 5 MB)</UploadInstruction>
                            </FileUploadLabel>
                        }
                    </UploadContainer>
                </Section> */}
                {
                    !isLoading && <SubmitButton onClick={submitOffer}>{ firstOffer ?"Send Renewal Request":"Submit Offer"}</SubmitButton>
                }
                
            </ModalWrapper>
        </ModalOverlay>
        ,
        document.getElementById('modal-root')
    )
}

export default RenewalOffer