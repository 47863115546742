import styled from "styled-components";
import { FaCircle } from "react-icons/fa"; // This is a filled circle that can act as a bullet

export const Card = styled.div``;

export const NameDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => (props.spacing ? "3px" : "5px")};
`;

export const PropertyCard = styled.div`
    cursor: pointer;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    // width: 92%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border: ${({ selected }) =>
        selected ? "2px solid #007BFF" : "2px solid #ddd"};
    &:hover {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
        transition: box-shadow 0.2s;
    }
`;

export const PropertyTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    // margin: 0 0 10px;
    font-weight: 500;
    font-size: 20px;
    color: #333;
`;

export const PropertyText = styled.div`
    // margin: 5px 0;
    color: #555;
`;

export const Price = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #27ae60;
    // grid-column: span 2;
`;

export const Status = styled.div`
    padding: 5px 10px;
    display: inline-block;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    // grid-column: span 2;
    color: #e74c3c;
    border: 2px solid #e74c3c;
    background: transparent;
    &::before {
        content: "• ";
    }
`;

export const FlexDiv = styled.div`
    display: flex;
    align-items: center;
`;

const color = ({ status }) => {
    if (status === false) {
        return `    
            background-color: #FFF4E5; // soft amber
            color: #FFA500; // amber
        `;
    } else if (status === true) {
        return `
            background-color: #E3FFE6;
            color: #1CCB00;
        `;
    }
};

export const StatusBadge = styled.div`
    ${color}
    border-radius: 15px; // Fully rounded corners
    padding: 5px 10px; // Vertical and horizontal padding
    font-size: 12px; // Font size
    font-weight: 500; // Medium font weight
    user-select: none; // Prevent text selection
    display: inline-flex; // Use inline-flex to center content
    align-items: center; // Vertical align to center
    width: fit-content;
    justify-content: center; // Horizontal align to center
    text-edge: cap;
    margin-left: auto;
`;

export const BulletIcon = styled(FaCircle)`
    font-size: 8px; // Size it to look like a bullet
    vertical-align: middle; // Align with the text
    margin-right: 4px; // Space after the bullet
`;
