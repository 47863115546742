import React, { createContext, useRef, useCallback, useState, useEffect, useContext } from 'react';
import io from 'socket.io-client';
import { UserContext } from './userContext';

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    const { accessToken } = useContext(UserContext) || {};  // ✅ Prevents undefined errors
    const socketRef = useRef(null);
    const isSocketReadyRef = useRef(false);
    const [socketReady, setSocketReady] = useState(false);

    const subscribersRef = useRef([]);
    const notificationsRef = useRef([]);
    const signedRef = useRef([]);
    const countRef = useRef([]);

    // ✅ Ensures socket is initialized only once
    const initializeSocket = () => {
        if (!socketRef.current) {
            console.log("🚀 Initializing socket...");
            socketRef.current = io('/chat', { autoConnect: false });

            socketRef.current.on('connect', () => {
                console.log("✅ Socket Connected");
                isSocketReadyRef.current = true;
                setSocketReady(true);

                if (accessToken) {
                    console.log("🔑 Auto-authenticating user socket...");
                    socketRef.current.emit('authenticate', {
                        token: accessToken,
                        member_type: "USER"
                    });
                }
            });

            socketRef.current.on('disconnect', (reason) => {
                console.warn("⚠️ Socket Disconnected. Reason:", reason);
                isSocketReadyRef.current = false;
                setSocketReady(false);
            });

            socketRef.current.on('connect_error', (error) => {
                console.error("❌ Socket connection error:", error);
            });

            // ✅ Preserve all existing handlers
            socketRef.current.on('new_chat_messages', (message) => {
                subscribersRef.current.forEach((callback) => callback(message));
            });

            socketRef.current.on('new_notifications', (notification) => {
                notificationsRef.current.forEach((callback) => callback(notification));
            });

            socketRef.current.on('chat_read_count', (newCount) => {
                countRef.current.forEach((callback) => callback(newCount));
            });

            socketRef.current.on('form_signed', (data) => {
                signedRef.current.forEach((callback) => callback(data));
            });

            socketRef.current.connect();
        }
    };

    // 🔥 Ensure socket initializes once when component mounts
    useEffect(() => {
        initializeSocket();
        return () => {
            if (socketRef.current) {
                console.warn("🔴 Disconnecting socket...");
                socketRef.current.disconnect();
                socketRef.current = null;  // ✅ Ensures cleanup
            }
        };
    }, []);

    // ✅ Keep authSocket for AGENTS (manual authentication)
    const authSocket = useCallback((agentId = null) => {
        const socket = socketRef.current;

        if (!isSocketReadyRef.current) {
            console.warn("⚠️ Socket is not ready yet. Cannot authenticate.");
            return;
        }

        console.log("🔑 Authenticating agent socket...");
        if (agentId) {
            socket.emit('authenticate', {
                member_id: agentId,
                member_type: "AGENT"
            });
        } else {
            console.warn("⚠️ No credentials provided for authentication.");
        }
    }, []);

    // ✅ Subscription methods
    const subscribeToNewNotifications = useCallback((callback) => {
        notificationsRef.current.push(callback);
        return () => {
            notificationsRef.current = notificationsRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToNewMessages = useCallback((callback) => {
        subscribersRef.current.push(callback);
        return () => {
            subscribersRef.current = subscribersRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToReadMessage = useCallback((callback) => {
        countRef.current.push(callback);
        return () => {
            countRef.current = countRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    const subscribeToSignForm = useCallback((callback) => {
        signedRef.current.push(callback);
        return () => {
            signedRef.current = signedRef.current.filter((cb) => cb !== callback);
        };
    }, []);

    // ✅ Ensure socket is always accessible
    const value = React.useMemo(() => ({
        socket: socketRef.current,
        isSocketReady: isSocketReadyRef.current,
        authSocket,  // Manual authentication for agents
        subscribeToNewMessages,
        subscribeToNewNotifications,
        subscribeToReadMessage,
        subscribeToSignForm
    }), [
        authSocket,
        subscribeToNewMessages,
        subscribeToNewNotifications,
        subscribeToReadMessage,
        subscribeToSignForm,
        socketReady
    ]);

    return (
        <SocketContext.Provider value={value}>
            {children}
        </SocketContext.Provider>
    );
};
