import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react';
import IframeAutoHeight from './iframe-height';
import { throttle } from '../../../../utilities/utility';
import { ApiContext } from '../../../../context/apiContext';
import { toast } from 'react-toastify'
import ImageModal from '../../../image-modals/image-modals';
// import { PDFDocument } from 'pdf-lib'
import { toProperCase } from '../../../../utilities/utility';
import {
    Container,
    Sidebar,
    SidebarSection,
    SidebarSubsection,
    Content,
    DocumentSection,
    AddDocs,
    NoDocDiv,
    NoDocText,
    ButtonDiv,
    RoundDiv,
    ZoomButton
  } from './package.styles'

import { ReactComponent as Document } from '../../../../assets/document-upload.svg'
import Loader from '../../../loader/loader';
// import { ReactComponent as Time } from '../../../../assets/Time Circle.svg'

const  Package =({ applicants, applicationPackage, setActiveTab, applicationId, isLoading = false}) => {
    const [activeSectionSmall, setActiveSectionSmall] = useState(null);
    const [activeSectionLarge, setActiveSectionLarge] = useState(null);
    const [expandedSectionSmall, setExpandedSectionSmall] = useState(null);
    const [expandedSectionLarge, setExpandedSectionLarge] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false); // ✅ Track fullscreen mode
    const contentRefsSmall = useRef({});
    const contentRefsLarge = useRef({});
    // const contentRef = useRef(null);
    const contentRefSmall = useRef(null);  // Small screen ref
    const contentRefLarge = useRef(null);  // Large screen ref (fullscreen)

    const api = useContext(ApiContext);

    // console.log(applicants)

    const docPriority = {
        "photo Id":1,
        "passport": 2,
        "i20": 3,
        "visa": 4,
        "loan sanction letter": 5,
        "guarantor form": 6,
        "pay stubs(1)": 7,
        "pay stubs(2)": 8,
        "offer letter": 9,
        "admit letter": 10,
        // Add other document types and their order here
    };

    const sectionOrder = {
        "Application Package": 1,
        "Application Forms": 2,
        "Guarantor": 3,
        "Supporting Documents": 4,
    };

    const sortDocuments = (a, b) => {
        return (docPriority[a.documentType.toLowerCase()] || Infinity) - (docPriority[b.documentType.toLowerCase()] || Infinity);
    };

    const sortSections = (a, b) => {
        return (sectionOrder[a.section] || Infinity) - (sectionOrder[b.section] || Infinity);
    };

    const getSections = useCallback((applicants, applicationPackage) => {
        let sections = [];

        // Check if applicationPackage exists and add it as a new section
        if (applicationPackage?.value) {
            sections.push({
                key: 'application-package',
                section: 'Application Package',
                subSection: 'Application Package',
                document: applicationPackage.value? ((window.location.origin.includes('localhost')? 'http://localhost:8000': window.location.origin) + '/media/' + applicationPackage.value): null,
                docName: 'Application Package',
                docHeight: applicationPackage.height // Set a default height or customize as needed
            });
        }

        applicants.forEach(app => {
        // Application forms
            const applicationDocs = app.documents.filter(doc => doc.documentType === 'Application Form');
            sections = sections.concat(applicationDocs.map(doc => ({
                key: `application-${doc.id}`,
                id: doc.id,
                section: 'Application Forms',
                subSection: `${app.name} - Application Form`,
                document:  doc.value? ((window.location.origin.includes('localhost')? 'http://localhost:8000': window.location.origin) + '/media/' + doc.value): null,
                email: app.email,
                docName: doc.documentType,
                docHeight: doc.height - 150
            })));
      
            // Guarantor forms
            if(app.hasCosigner){
                const guarantorDocs = app.cosigner.documents.sort(sortDocuments);
                sections = sections.concat(guarantorDocs.map(doc => ({
                    key: `guarantor-${doc.id}`,
                    id: doc.id,
                    section: 'Guarantor',
                    subSection: `${app.cosigner.name} - ${doc.documentType}`,
                    sortHint: docPriority[doc.documentType.toLowerCase()] || Infinity,
                    document:  doc.value? ((window.location.origin.includes('localhost')? 'http://localhost:8000': window.location.origin) + '/media/' + doc.value): null,
                    email: app.email,
                    docName: doc.documentType,
                    docHeight: doc.height - 150
                })));
            }
      
            // Supporting documents
            const supportingDocs = app.documents.filter(doc => doc.documentType !== 'Application Form').sort(sortDocuments);
            // console.log('supporting docs', supportingDocs)
            sections = sections.concat(supportingDocs.map(doc => ({
                key: `supporting-docs-${doc.id}`,
                id: doc.id,
                section: 'Supporting Documents',
                subSection: `${app.name} - ${doc.documentType}`,
                sortHint: docPriority[doc.documentType.toLowerCase()] || Infinity,
                document: doc.value? window.location.origin + '/media/' + doc.value: null,
                email: app.email,
                docName: doc.documentType,
                docHeight: doc.height - 150
            })));
        });

        // // Sort sections by predefined order and then by name within each section
        sections.sort((a, b) => {
            const sectionComparison = sortSections(a, b);
            if(sectionComparison !== 0) return sectionComparison;
            return sections
            // const nameComparison = a.subSection.localeCompare(b.subSection);
            // if (nameComparison !== 0) return nameComparison;
            // // When names are the same, sort by sortHint
            // return a.sortHint - b.sortHint;
        });
        return sections;
    },[]);

    const groupSectionsByTitle = useCallback((sections) => {
        const groupedSections = sections.reduce((acc, curr) => {
            // Check if the section already exists
            if (!acc[curr.section]) {
                acc[curr.section] = {
                title: curr.section,
                subsections: [],
                };
            }
            // Add the current subsection to the appropriate section
            acc[curr.section].subsections.push(curr);
            return acc;
        }, {});
        return Object.values(groupedSections); // Convert the grouped sections object back into an array
    },[]);

    const sections = useMemo(() => getSections(applicants, applicationPackage) || [], [applicants, applicationPackage]);
    const groupedSections = useMemo(() => groupSectionsByTitle(sections) || [], [sections]);


    /** Separate Click Handlers **/
    const handleSectionClickSmall = useCallback((sectionTitle) => {
        const firstSubKey = groupedSections.find(sec => sec.title === sectionTitle)?.subsections[0]?.key;
        const sectionElement = firstSubKey ? contentRefsSmall.current[firstSubKey] : contentRefsSmall.current[sectionTitle];

        if (sectionElement && contentRefSmall.current) {
            const topPos = sectionElement.offsetTop - contentRefSmall.current.offsetTop;
            contentRefSmall.current.scrollTo({ top: topPos, behavior: 'smooth' });
        }
    }, []);

    const handleSectionClickLarge = useCallback((sectionTitle) => {
        const firstSubKey = groupedSections.find(sec => sec.title === sectionTitle)?.subsections[0]?.key;
        const sectionElement = firstSubKey ? contentRefsLarge.current[firstSubKey] : contentRefsLarge.current[sectionTitle];
    
        if (sectionElement && contentRefLarge.current) {
            const topPos = sectionElement.offsetTop - contentRefLarge.current.offsetTop;
            contentRefLarge.current.scrollTo({ top: topPos, behavior: 'smooth' });
    
            // ✅ Ensure section expands when clicked
            setExpandedSectionLarge(sectionTitle);
        }
    }, []);



    const attachScrollListener = (contentRef, contentRefs, setActiveSection, setExpandedSection) => {
        if (!contentRef.current) return;
    
        const handleScroll = throttle(() => {
            let newActiveSection = null;
            const contentContainer = contentRef.current;
            if (!contentContainer) return;
    
            const scrollPosition = contentContainer.scrollTop ?? 0;
    
            Object.entries(contentRefs.current).forEach(([key, ref]) => {
                if (ref) {
                    const sectionOffset = ref.offsetTop - contentContainer.offsetTop - 10;
                    if (scrollPosition >= sectionOffset && scrollPosition < sectionOffset + ref.clientHeight) {
                        newActiveSection = key;
                    }
                }
            });
    
            if (newActiveSection) {
                setActiveSection(newActiveSection);
                const parentSection = sections.find(sec => sec.key === newActiveSection)?.section || null;
                if (parentSection) {
                    setExpandedSection(parentSection);
                }
            }
        }, 20);
    
        contentRef.current.addEventListener('scroll', handleScroll);
    
        return () => {
            contentRef.current?.removeEventListener('scroll', handleScroll);
        };
    };
    

    useEffect(() => {
        if (!sections.length) return;
    
        const cleanupSmall = attachScrollListener(
            contentRefSmall,
            contentRefsSmall,
            setActiveSectionSmall,
            setExpandedSectionSmall
        );
    
        return cleanupSmall;
    }, [sections, activeSectionSmall]);
    
    useEffect(() => {
        if (!sections.length || !isFullScreen) return; // Only attach for full-screen mode
    
        const interval = setInterval(() => {
            if (contentRefLarge.current) {
                clearInterval(interval);
    
                const cleanupLarge = attachScrollListener(
                    contentRefLarge,
                    contentRefsLarge,
                    setActiveSectionLarge,
                    setExpandedSectionLarge
                );
    
                return cleanupLarge;
            }
        }, 100);
    
        return () => clearInterval(interval); // Cleanup interval if modal closes before ref is ready
    }, [sections, activeSectionLarge, expandedSectionLarge, isFullScreen]);
    
    


    
    const handleReminder = async (sub) => {
        // const log = console.log;
        const reminderData = {
            email: sub.email,
            documentName: sub.docName,
            documentId: sub.id,
            applicationId: applicationId
        };
      
        try {
            const response = await api.put('/api/application/document/reminder', reminderData);
            if (!response.success) {
                throw new Error(`HTTP error! Status: ${response.message}`);
            }
            
            toast.success('Reminder sent successfully');

        } catch (error) {
            toast.error(error.message);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    };
    

    const openFullScreenViewer = () => {
        setIsFullScreen(true);
        setIsModalOpen(true);
        // ✅ Reset sidebar selection
        setActiveSectionLarge(null);
        setExpandedSectionLarge(null);
    };
    



    return (
        <Container>
            {
                isLoading && <Loader />
            }
            <Sidebar>
            {
                groupedSections.map((section) => (
                    <React.Fragment key={section.title}>
                        <SidebarSection 
                            active = {section.subsections.some(sub => sub.key === activeSectionSmall)}
                            onClick={() => handleSectionClickSmall(section.title)}
                        >
                            {section.title}
                        </SidebarSection>
                        {
                            expandedSectionSmall === section.title && section.subsections.map((sub) => (
                                <SidebarSubsection
                                    key={sub.key}
                                    onClick={() => handleSectionClickSmall(sub.key)}
                                    active={activeSectionSmall === sub.key}
                                >
                                    {toProperCase(sub.subSection)}
                                </SidebarSubsection>
                            ))
                        }
                    </React.Fragment>
                ))
            }
            <AddDocs onClick={()=>setActiveTab('Additional Docs')}><span>Request Other Documents</span><span><strong>&#43;</strong></span></AddDocs>
            </Sidebar>
            <Content ref={contentRefSmall}>
                {
                    groupedSections.map((section) => (
                        <React.Fragment key={section.title}>
                            {
                                section.subsections.map((sub) => (
                                    <DocumentSection
                                        key={sub.key}
                                        ref={(el) => contentRefsSmall.current[sub.key] = el}
                                    >
                                        {
                                            sub.document ? 
                                            <IframeAutoHeight 
                                                src={sub.document} 
                                                height = {sub.docHeight}
                                            />
                                            :
                                            <NoDocDiv>
                                                <RoundDiv><Document/></RoundDiv>
                                                <NoDocText>Document not uploaded yet</NoDocText>
                                                <ButtonDiv onClick={() => handleReminder(sub)}>Remind</ButtonDiv>
                                            </NoDocDiv>
                                        }
                                    </DocumentSection>
                                ))
                            }
                        </React.Fragment>
                    ))
                }
            </Content>
            <ZoomButton onClick={openFullScreenViewer}>🔍</ZoomButton>
            {isModalOpen && (
                <ImageModal onClose={closeModal}>
                    <div style={{
                        display: "flex",
                        width: "90vw",
                        height: "100%",
                        background: "#fff",
                        overflow: "hidden" // 🔥 Prevents unnecessary horizontal scroll
                    }}>
                        {/* Left Panel - Sidebar */}
                        <Sidebar style={{
                            width: "280px",
                            height: "100%",
                            overflowY: "auto",
                            borderRight: "1px solid #ddd",
                            flexShrink: 0 // 🔥 Prevents sidebar from shrinking
                        }}>
                            {groupedSections.map((section) => (
                                <React.Fragment key={section.title}>
                                    <SidebarSection 
                                        active={section.subsections.some(sub => sub.key === activeSectionLarge)}
                                        onClick={() => handleSectionClickLarge(section.title)}
                                    >
                                        {section.title}
                                    </SidebarSection>
                                    {expandedSectionLarge === section.title && section.subsections.map((sub) => (
                                        <SidebarSubsection
                                            key={sub.key}
                                            onClick={() => handleSectionClickLarge(sub.key)}
                                            active={activeSectionLarge === sub.key}
                                        >
                                            {toProperCase(sub.subSection)}
                                        </SidebarSubsection>
                                    ))}
                                </React.Fragment>
                            ))}
                            <AddDocs onClick={closeModal}>Close</AddDocs>
                        </Sidebar>
            
                        {/* Right Panel - Documents */}
                        <Content ref={contentRefLarge} style={{
                            flexGrow: 1,
                            overflowY: "auto",
                            padding: "20px", // 🔥 Ensures padding is applied properly
                            maxWidth: "calc(100% - 280px)" // 🔥 Prevents unnecessary horizontal scroll
                        }}>
                            {groupedSections.map((section) => (
                                <React.Fragment key={section.title}>
                                    {section.subsections.map((sub) => (
                                        <DocumentSection key={sub.key} ref={(el) => contentRefsLarge.current[sub.key] = el}>
                                            {sub.document ? (
                                                <IframeAutoHeight src={sub.document} height={sub.docHeight} />
                                            ) : (
                                                <NoDocDiv>
                                                    <RoundDiv><Document /></RoundDiv>
                                                    <NoDocText>Document not uploaded yet</NoDocText>
                                                    <ButtonDiv onClick={() => handleReminder(sub)}>Remind</ButtonDiv>
                                                </NoDocDiv>
                                            )}
                                        </DocumentSection>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Content>
                    </div>
                </ImageModal>
            )}
        </Container>
    );
}

export default Package