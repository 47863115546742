import React, { useState, useRef, useEffect, useContext } from "react";
import ChatMessage from "../chat-message/chat-message";
import ChatInput from "../chat-input/chat-input";
import { ApiContext } from "../../context/apiContext";
import { SocketContext } from "../../context/socketContext";
import { UserContext } from "../../context/userContext";

import {
    Container,
    MessageList,
    ArrowListContainer,
    ArrowDiv,
    LoadingDiv
} from './agent-chat.styles'

const ChatChannel = ({groupId, agentId}) => {
    // console.log(groupId, agentId)
    const { socket, subscribeToNewMessages } = useContext(SocketContext)
    const { user } = useContext(UserContext);
    const api = useContext(ApiContext)
    const [chatMessages, setChatMessages] = useState([]);
    const [chatText, setChatText] = useState('');
    const [attachment, setAttachment] = useState({
        file: null,
        url: '',
    });
    const [nextPageUrl, setNextPageUrl] = useState(null); // Store the next page URL

    const chatListRef = useRef(null);
    const chatEndRef = useRef(null); // Ref for the comments list container
    const scrollToBottomRef = useRef(null); // Ref for the comments list container
    const loadingRef = useRef(null);

    const fetchChatMessages = async (url = null) => {
        // console.log('[fetchChatMessages] Fetching chat messages...', user);
        
        const memberData = user?.id || agentId;
        const memberType = user?.id ? 'USER' : 'AGENT';
        const urlFetch = url || `/api/chat/history?groupId=${groupId}&member_id=${memberData}&member_type=${memberType}`;
        loadingRef.current = url ? true: false;
        // console.log(`[fetchChatMessages] URL: ${urlFetch}`);
        try {
            const data = user?.id ? await api.get(urlFetch) : await (await fetch(urlFetch)).json();
            // console.log('[fetchChatMessages] Response data:', data);
            const sortedMessages = data.results.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
            setChatMessages(prev => url ? [...sortedMessages, ...prev] : sortedMessages);
            setNextPageUrl(data.next);
        } catch (error) {
            console.error('[fetchChatMessages] Error fetching chat messages:', error);
        }
    };

    useEffect(() => {
        // Clear chat messages and next page URL whenever groupId or agentId changes
        setChatMessages([]);
        setNextPageUrl(null);
        scrollToBottomRef.current.style.display = 'none';
        // fetchChatMessages();
    }, [groupId, agentId]); // Added agentId t

    useEffect(() => {
        // console.log('[useEffect] Fetching initial chat history...');
        fetchChatMessages(); 

        return async () => {
            const memberData = user?.id || agentId;
            const memberType = user?.id ? 'USER' : 'AGENT';
            await user?.id ? api.get(`/api/chat/history?groupId=${groupId}&member_id=${memberData}&member_type=${memberType}`)
            :
            (await fetch(`/api/chat/history?groupId=${groupId}&member_id=${memberData}&member_type=${memberType}`))
        }
    }, [groupId, agentId]);

    useEffect(() => {
        // Fetch initial chat history...
        // console.log('[useEffect] Subscribing to new messages...');
        // Subscribe to new chat messages
        const unsubscribe = subscribeToNewMessages((newMessage) => {
            // console.log('[useEffect] Received new message:', newMessage);
            if (newMessage.groupId === groupId) {
                setChatMessages((prev) => [...prev, newMessage]);
            }
        });

        return () =>{
            // console.log('[useEffect] Unsubscribing from new messages...');
            unsubscribe();
        };
    }, [ subscribeToNewMessages, groupId]);


    useEffect(() => {
        if(loadingRef.current){
            scrollToOffset();
            loadingRef.current = false
        }else{
            scrollToBottom();
        }
    }, [chatMessages, loadingRef]); // Dependency on comments to auto-scroll on update

    const scrollToBottom = () => {
        // console.log('[scrollToBottom] Scrolling to the bottom...');
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const scrollToOffset = () => {
        // console.log('[scrollToOffset] Scrolling to offset...');
        if (chatListRef.current) {
            // Scrolls 50px up from the current scroll position
            chatListRef.current.scrollTop = chatListRef.current.clientHeight + 200 ;
        }
    };

    const handleEnter = (e) => {
        if (e.key === 13 && !e.shiftKey) {
            handleNewMessage(e);
        }
    };

    const handleNewMessage = async (e) => {
        e.preventDefault();
        if (chatText.trim() === "<p><br></p>" && !attachment.file) return; 
        // console.log('[handleNewMessage] Sending a new message...');
        if(!attachment.file && chatText === '') return
        if (attachment.file) {
            // console.log('[handleNewMessage] Sending a message with an attachment...');
            const formData = new FormData();
            formData.append('file', attachment.file); 

            try {
                let data 
                if(user?.id ){
                    // console.log('[handleNewMessage] Uploading file via API...');
                    data =  await api.post(`/api/chat/upload_file/`, formData)
                }else{
                    try {
                        // console.log('[handleNewMessage] Uploading file via fetch...');
                        const response = await fetch(`/api/chat/upload_file/`, {
                            method: 'POST',  // Explicitly specifying the method
                            body: formData,  // Setting the formData as the body of the request
                            // No 'Content-Type' header included, as the browser will automatically set it
                            // to 'multipart/form-data' with the proper 'boundary' when 'formData' is used.
                        });
                
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);  // Handle HTTP errors
                        }

                        data = await response.json();  // Parse JSON response
                        // console.log('[handleNewMessage] File uploaded successfully:', data);
                    } catch (error) {
                        console.error('Failed to upload file:', error);  // Handle exceptions
                        data = { error: 'Failed to upload file' };
                    }
                }
                
                // console.log(data)
                // Assuming the response JSON structure is { url: "..." }
                // Emit the binary data with socket.io
                socket.emit('send_message', {
                    message: chatText,
                    group_id: groupId,
                    file_url: data.file
                });
            } catch (error) {
                console.error('Failed to upload file:', error);
            }
        } else {
            // console.log('[handleNewMessage] Sending a text-only message...');
            socket.emit('send_message', {
                message: chatText,
                group_id: groupId
            });
        }
        resetForm();
    };

    const resetForm = () => {
        // console.log('[resetForm] Resetting the message input form...');
        setChatText('');
        setAttachment({ file: null, url: '' });
    };

    const isUserAtBottom = () => {
        if (!chatListRef.current) return false;
    
        const { scrollTop, clientHeight, scrollHeight } = chatListRef.current;
        // Check if the user is at the bottom of the comments list
        // This is true if the scrollTop (how far the user has scrolled) plus the clientHeight (the visible height of the element)
        // is equal to the scrollHeight (the total height of the content), with a small tolerance to account for fractional pixel values and rendering inconsistencies.
        const bottomTolerance = 25; // pixels
        return scrollTop + clientHeight + bottomTolerance >= scrollHeight;
    };

    const handleScroll = () => {
        // Logic to show/hide the scrollToBottomRef based on scroll position
        const isAtBottom = isUserAtBottom();
        scrollToBottomRef.current.style.display = isAtBottom ? 'none' : 'flex';

        if (chatListRef.current.scrollTop === 0 && nextPageUrl) {
            // chatListRef.current.scrollTop += 50
            // console.log('[handleScroll] User reached top, fetching more messages...');
            fetchChatMessages(nextPageUrl); // Load more messages when scrolled to the top and there are more messages to load
        }
    };

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileUrl = URL.createObjectURL(file);
            setAttachment({
                file: file,
                url: fileUrl
            });
        }
    };


    return (
        <Container>
            <ArrowListContainer>
                <ArrowDiv ref={scrollToBottomRef} onClick={scrollToBottom}>
                    ↓
                </ArrowDiv>
                <MessageList ref={chatListRef} onScroll={handleScroll}>
                    {
                        nextPageUrl &&
                        <LoadingDiv>Loading</LoadingDiv>
                    }
                    {
                        chatMessages.map((message, index) => (
                            // Render each message in a styled container
                            <ChatMessage key={index} message={message} />
                        ))
                    }
                    <div ref={chatEndRef}/>
                </MessageList>
            </ArrowListContainer>
            <ChatInput
                handleSubmit={handleNewMessage}
                resetForm = {resetForm} 
                attachment = {attachment} 
                commentText = {chatText} 
                setCommentText = {setChatText} 
                // handleEnter = {handleEnter} 
                handleFileChange = {handleFileChange} 
                triggerFileInput={triggerFileInput}
            />
        </Container>
    )
}

export default ChatChannel