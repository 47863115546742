import React, { useState, useContext } from "react";
import SendMessageModal from "../../message-modality/message-modality";
import { ApiContext } from "../../../context/apiContext";
import { toast } from 'react-toastify'
import { formatDate } from "../../../utilities/utility";
import {
    Canvas,
    IconButton,
    Th,
    ApplicantName,
    BulletIcon,
    StatusBadge,
    StyledTable,
    TableRow,
    TableCellWrapper,
    IconDiv,
    FlexDivColumn,
    Heading,
    SubscriptText,
    CreateLeaseDiv,
    LeasePackageDiv,
    LeaseCard,
    DateDiv,
    DateText,
} from './forms.styles'

import { ReactComponent as Message } from '../../../assets/Message.svg'
import { ReactComponent as Reminder } from '../../../assets/reminder.svg'
import { ReactComponent as View } from '../../../assets/view.svg'
import { ReactComponent as Download } from '../../../assets/Download.svg'


const Forms = ({ tenants, applicants, renewalId, lease, showLeaseRenewalForm, showRenewalModal, totalApplicants, renewalForm}) => {

    const api = useContext(ApiContext)
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [tenantsToSend, setTenantsToSend] = useState([]);

    const handleOpenModalForOne = (tenant) => {
        setTenantsToSend([tenant]); // Set only one tenant
        setIsMessageModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsMessageModalOpen(false);
    };

    const sendReminder = async (id, type) => {
        try {
            // const data = await api.post(`/api/renewal/remind`, { renewalId });
            const data = await api.get(`/api/renewal/remind`,{tenantId: id, renewalId: renewalId});
            toast.info(`Reminder sent to ${type}`);
        }catch(error){
            toast.error(error);
        }
    }


    return (
        <Canvas>
            {
                totalApplicants === 0 ? 
                <FlexDivColumn>
                    <FlexDivColumn style = {{gap: '15px'}}>
                        <Heading>Renewal Forms</Heading>
                        <LeaseCard leaseDoc = {renewalForm?.renewalFormDoc}>
                            {
                                renewalForm && 
                                <DateDiv>
                                    <SubscriptText>Renewal Form Due Date:</SubscriptText>
                                    <DateText> {formatDate(new Date(renewalForm?.renewalFormDueDate)).formattedDate}</DateText>
                                </DateDiv>   
                            }
                            {
                                renewalForm?.renewalFormDoc &&
                                <LeasePackageDiv onClick={showLeaseRenewalForm}>Renewal Agreement <Download/></LeasePackageDiv>
                            }
                        </LeaseCard>
                    </FlexDivColumn>
                    <FlexDivColumn style = {{gap: '15px'}}>
                    <Heading>Renewal Forms Status</Heading>
                        <StyledTable>
                            <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                                <tr>
                                    <Th><TableCellWrapper>Tenant</TableCellWrapper></Th>
                                    <Th><TableCellWrapper>Signature Status</TableCellWrapper></Th>
                                    <Th><TableCellWrapper>Action</TableCellWrapper></Th>
                                </tr>
                            </thead>
                            {
                                tenants.map(tenant => 
                                    <TableRow>
                                        <ApplicantName><TableCellWrapper>{tenant.name}</TableCellWrapper></ApplicantName>
                                        <td>
                                            <TableCellWrapper>
                                                <StatusBadge status = {tenant.renewalFormStatus}>
                                                    <BulletIcon/>
                                                    {tenant.renewalFormStatus}
                                                </StatusBadge>
                                            </TableCellWrapper>
                                        </td>
                                        <td>
                                            <IconDiv>
                                                <TableCellWrapper>
                                                    <IconButton 
                                                        onClick = {() => sendReminder(tenant.tenantId, 'tenant')}
                                                    >
                                                        <Reminder/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                                {/* <TableCellWrapper>
                                                    <IconButton
                                                    // TODO: Need to add onClick function
                                                    >
                                                        <View stroke = {'#064274'}/>
                                                    </IconButton>
                                                </TableCellWrapper> */}
                                            </IconDiv>
                                        </td>
                                    </TableRow>
                                )
                            }
                        </StyledTable>
                    </FlexDivColumn>
                </FlexDivColumn>
                :
                <FlexDivColumn style = {{gap: '30px'}}>
                    <FlexDivColumn style = {{gap: '15px'}}>
                        <Heading>Lease</Heading>
                        <LeaseCard leaseDoc = {lease.leaseDoc}>
                            {/* {
                                lease.leaseDueDate && 
                                <DateDiv>
                                    <SubscriptText>Lease Due Date:</SubscriptText>
                                    <DateText> {formatDate(new Date(lease.leaseDueDate)).formattedDate}</DateText>
                                </DateDiv>   
                            } */}
                            {
                                lease.leaseDoc ?
                                <LeasePackageDiv onClick={showLeaseRenewalForm}>Lease Agreement <Download/></LeasePackageDiv>
                                :
                                <CreateLeaseDiv onClick={showRenewalModal}><span>+</span><span>Create Lease</span></CreateLeaseDiv>
                            }
                        </LeaseCard>
                    </FlexDivColumn>
                    <FlexDivColumn style = {{gap: '15px'}}>
                        <Heading>Lease Status</Heading>
                        <StyledTable>
                            <thead style={{borderBottom:'1px solid #f1f1f1'}}>
                                <tr>
                                    <Th><TableCellWrapper>Name</TableCellWrapper></Th>
                                    <Th><TableCellWrapper>Signature Status</TableCellWrapper></Th>
                                    <Th><TableCellWrapper>Action</TableCellWrapper></Th>
                                </tr>
                            </thead>
                            {
                                tenants.filter(tenant => tenant.renewalStatus !== "Denied").map(tenant => 
                                    <TableRow>
                                        <ApplicantName><TableCellWrapper>{tenant.name}</TableCellWrapper></ApplicantName>
                                        <td>
                                            <TableCellWrapper>
                                                <StatusBadge status = {tenant.leaseStatus}>
                                                    <BulletIcon/>
                                                    {tenant.leaseStatus}
                                                </StatusBadge>
                                            </TableCellWrapper>
                                        </td>
                                        <td>
                                            <IconDiv>
                                                <TableCellWrapper>
                                                    <IconButton 
                                                        onClick = {() => sendReminder(tenant.tenantId, 'tenant')}
                                                    >
                                                        <Reminder/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                                <TableCellWrapper>
                                                    <IconButton
                                                        onClick={() => handleOpenModalForOne(tenant)}
                                                    >
                                                        <Message/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                            </IconDiv>
                                        </td>
                                    </TableRow>
                                )
                            }
                            {/* {
                                applicants.map(applicant => 
                                    <TableRow>
                                        <ApplicantName><TableCellWrapper>{applicant.name}</TableCellWrapper></ApplicantName>
                                        <td>
                                            <TableCellWrapper>
                                                <StatusBadge status = {applicant.leaseStatus}>
                                                    <BulletIcon/>
                                                    {applicant.leaseStatus}
                                                </StatusBadge>
                                            </TableCellWrapper>
                                        </td>
                                        <td>
                                            <IconDiv>
                                                <TableCellWrapper>
                                                    <IconButton 
                                                        onClick = {() => sendReminder(applicant.id, 'applicant')}
                                                    >
                                                        <Reminder/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                                <TableCellWrapper>
                                                    <IconButton
                                                    
                                                    >
                                                        <Message  onClick={() => handleOpenModalForOne(applicant)}/>
                                                    </IconButton>
                                                </TableCellWrapper>
                                            </IconDiv>
                                        </td>
                                    </TableRow>
                                )
                            } */}
                        </StyledTable>
                    </FlexDivColumn>
                </FlexDivColumn>
            }
            <SendMessageModal
                isOpen={isMessageModalOpen}
                onClose={handleCloseModal}
                tenants={tenantsToSend}
                tenantsList={applicants}
                setTenantsToSend={setTenantsToSend}
            />
        </Canvas>
    )
}

export default Forms

