import React, { useContext, useState } from "react";
import FormInput from "../form-input/form-input";
import {
  Section,
  SectionTitle,
  ApplicantContainer,
  WrapDiv,
  FlexDiv,
  GapDiv,
  NextButton,
  CloseButton,
} from "./applicant-guarantor-modality.style";
import { ApiContext } from "../../context/apiContext";
import { toast } from "react-toastify";

const ApplicantsGuarantorsForm = ({ applicants, guarantors, onClose, updateDeal, dealId }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({ applicants, guarantors });
  const api = useContext(ApiContext);

  const validateApplicantsGuarantors = () => {
    let newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    formData.applicants.forEach((applicant, index) => {
      if (!applicant.name) newErrors[`applicantName${index}`] = "Name is required";
      if (!applicant.email) {
        newErrors[`applicantEmail${index}`] = "Email is required";
      } else if (!emailRegex.test(applicant.email)) {
        newErrors[`applicantEmail${index}`] = "Invalid email format";
      }
    });

    formData.guarantors.forEach((guarantor, index) => {
      if (!guarantor.name) newErrors[`guarantorName${index}`] = "Name is required";
      if (!guarantor.email) {
        newErrors[`guarantorEmail${index}`] = "Email is required";
      } else if (!emailRegex.test(guarantor.email)) {
        newErrors[`guarantorEmail${index}`] = "Invalid email format";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (index, field, value, type) => {
    setFormData((prev) => {
      const updated = {
        ...prev,
        [type]: prev[type].map((item, i) =>
          i === index ? { ...item, [field]: value } : item
        ),
      };
      return updated;
    });
  };
  
  const handleSubmit = async () => {
    if (!validateApplicantsGuarantors()) return;
  
    const payload = {
      dealId,
      applicants: formData.applicants.map(({ id, name, email }) => ({
        applicant_id: id || null,
        name,
        email_address: email,
      })),
      guarantors: formData.guarantors.map(({ id, name, email }) => ({
        guarantor_id: id || null,
        name,
        email_address: email,
      })),
    };
  
    try {
      const response = await api.put(`/api/application/participants`, payload);
  
      const normalizedApplicants = [...formData.applicants, ...response.updated_applicants].map(applicant => ({
        id: applicant.id || applicant.applicant_id, 
        name: applicant.name,
        email: applicant.email || applicant.email_address,
        applicantType: applicant.applicantType || applicant.applicant_type || null,
        leaseStatus: applicant.leaseStatus || 'Pending',
      }));
  
      const normalizedGuarantors = [...formData.guarantors, ...response.updated_guarantors].map(guarantor => ({
        id: guarantor.id || guarantor.guarantor_id,
        name: guarantor.name,
        email: guarantor.email || guarantor.email_address,
        guarantorFor: guarantor.guarantorFor || guarantor.guarantor_for || null,
        leaseStatus: guarantor.leaseStatus || 'Pending',
      }));
  
      const uniqueApplicants = [...new Map(normalizedApplicants.map(app => [app.id, app])).values()];
      const uniqueGuarantors = [...new Map(normalizedGuarantors.map(gua => [gua.id, gua])).values()];
  
      updateDeal({ dealId, applicants: uniqueApplicants, guarantors: uniqueGuarantors });
  
      onClose();
    } catch (error) {
      toast.error("An error occurred while updating details.");
      console.error("API Error:", error);
    }
  };
  
  

  return (
    <ApplicantContainer style={{ border: "unset" }}>
      <Section style={{ gap: "10px" }}>
        <FlexDiv>
          <SectionTitle>Applicants Detail</SectionTitle>
          <CloseButton onClick={onClose}>x</CloseButton>
        </FlexDiv>
        {formData.applicants.map((applicant, index) => (
          <WrapDiv key={index}>
            <FormInput
              label="Name"
              type="text"
              isRequired={true}
              defaultValue={applicant.name}
              handleChange={(value) => handleChange(index, "name", value, "applicants")}
              flexBasis={true}
              error={errors[`applicantName${index}`]}
            />
            <FormInput
              label="Email"
              type="text"
              isRequired={true}
              defaultValue={applicant.email}
              handleChange={(value) => handleChange(index, "email", value, "applicants")}
              flexBasis={true}
              error={errors[`applicantEmail${index}`]}
            />
          </WrapDiv>
        ))}
        {/* <SectionTitle>Guarantors Detail</SectionTitle>
        {formData.guarantors.map((guarantor, index) => (
          <WrapDiv key={index}>
            <FormInput
              label="Name"
              type="text"
              isRequired={true}
              defaultValue={guarantor.name}
              handleChange={(value) => handleChange(index, "name", value, "guarantors")}
              flexBasis={true}
              error={errors[`guarantorName${index}`]}
            />
            <FormInput
              label="Email"
              type="text"
              isRequired={true}
              defaultValue={guarantor.email}
              handleChange={(value) => handleChange(index, "email", value, "guarantors")}
              flexBasis={true}
              error={errors[`guarantorEmail${index}`]}
            />
          </WrapDiv>
        ))} */}
        {
          formData?.guarantors?.length > 0 && (
            <>
              <SectionTitle>Guarantors Detail</SectionTitle>
              {formData?.guarantors?.map((guarantor, index) => (
                <WrapDiv key={index}>
                  <FormInput
                    label="Name"
                  type="text"
                  isRequired={true}
                  defaultValue={guarantor.name}
                  handleChange={(value) => handleChange(index, "name", value, "guarantors")}
                  flexBasis={true}
                  error={errors[`guarantorName${index}`]}
                />
                <FormInput
                  label="Email"
                  type="text"
                  isRequired={true}
                  defaultValue={guarantor.email}
                  handleChange={(value) => handleChange(index, "email", value, "guarantors")}
                  flexBasis={true}
                  error={errors[`guarantorEmail${index}`]}
                />
              </WrapDiv>
            ))}
            </>
        )}
      </Section>
      <GapDiv>
        <NextButton onClick={handleSubmit}>Update</NextButton>
      </GapDiv>
    </ApplicantContainer>
  );
};

export default ApplicantsGuarantorsForm;
