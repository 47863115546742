import React from 'react';
import { UserProvider } from './userContext';
import { ApiProvider } from './apiContext';
import { SocketProvider } from './socketContext';
import { SubdomainProvider } from './subdomainContext';


const ContextProvider = ({ children }) => {
    return (
        <SubdomainProvider>
            <UserProvider>
                <SocketProvider>
                    <ApiProvider>
                        {children}
                    </ApiProvider>
                </SocketProvider>
            </UserProvider>

        </SubdomainProvider>
    );
};

export default ContextProvider;
