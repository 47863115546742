import styled from "styled-components";

// export const CodeInput = styled.input`
//     border: 1px solid var(--F1, #F1F1F1);
//     border-radius: 7px;
//     padding: 10px;
//     font-family: Avenir;
// `;

export const Canvas = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 180px 0px 180px;
    // align-items: center;
    background: #E5E5E5;
    width: 100vw;
    gap: 10px;
    height: -webkit-fill-available;
    overflow: auto;

    @media screen and (max-width: 1100px) {
        padding: 20px 10px 20px 10px;
    }
`;

export const Section = styled.div`
    display: flex;
    padding: 25px;
    flex-direction: column;
    background: #FFFFFF;
    height: fit-content;
    border-radius: 10px;
    gap: 15px;

    @media screen and (max-width: 1100px) {
        padding: 15px;
        gap: 10px;
    }
`;

export const ImageContainer = styled.div`
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;

    @media screen and (max-width: 600px){
        width: 100%;
        height: 150px;
    }
`;

export const PropertyImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const PropertyDetails = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;

    @media screen and (max-width: 600px){
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
`;

export const PropertyHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (max-width: 600px){
        padding: 0px 15px;
    }
`;

export const PropertyTitle = styled.div`
    font-size: 20px;
    color: #333;
    margin: 0;
    font-weight: 500;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }
`;

export const Title = styled(PropertyTitle)`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`;

export const AgentTitle = styled.div`
    font-size: 14px;
    color: #323232;
    margin: 0;
    @media screen and (max-width: 600px){
        font-size: 12px;
    }
`;

export const Heading = styled.div`
    color: #2C2C2C;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    @media screen and (max-width: 600px){
        font-size: 16px;
    }

`;


export const RowContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
`

export const TimeContainer = styled(Section)`
    color: var(--Greyish-Black, #323232);
    font-size: 12px;
    font-weight: 500;
    border: 1px solid var(--F1, #F1F1F1);
    border-radius: 7px; 
    padding: var(--10, 10px);
    // width: fit-content;
    gap: 10px;

    p{
        margin:0px;
        margin-bottom:6px;
    }

`;


export const TimeList = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
`;

export const TimeItem = styled.div`
    border-radius: 7px;
    padding: 5px;
    background: var(--F1, #F1F1F1);
    font-size: 11px;
    background: ${({ selected }) => (selected ? '#F0F8FF' : 'var(--F1, #F1F1F1)')}; // Change the color when selected
    color: ${({ selected }) => (selected ? '#064274' : 'var(--Greyish-Black, #323232)')};
    border: ${({ selected }) => (selected ? '1px solid' : '1px solid var(--F1, #F1F1F1)')};
    // cursor: pointer;
`;

export const FormTextArea = styled.textarea`
  padding: 10px;
  // margin-bottom: 10px;
  border: 1px solid var(--F1, #F1F1F1);
  border-radius: 7px;
  height: 100px;
  font-family: Avenir;
  resize: none;   
  overflow: auto;
`;

export const SubmitButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #064274;
    color: white;
    &:hover{ 
        background-color: #002456;
        transition: background-color 0.2s;
    }
`;

export const CancelButton = styled.button`
    border: none;
    border-radius: 10px;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    background-color: #E45A5C;
    color: white;
    &:hover{ 
        background-color: #E45A5C70;
        transition: background-color 0.2s;
    }
`;

export const CenteredSection = styled.div`
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    justify-content: center;
    align-items: center;
    display: flex;
`;
