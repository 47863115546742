import React, { useContext, useEffect, useState } from 'react'
import ChatList from '../../components/chat-list/chat-list'
import { ApiContext } from '../../context/apiContext';
import { toast } from 'react-toastify'
import {
    MessagingHeader,
    Title,
    ContentSection,
    ChatContentSection, 
    ChatHeader, 
    ChatContainer, 
    // NoChatMessage, 
    NoChatText,
    NoChatDiv
} from './messaging.pages.styles'
import ChatChannel from '../../components/agent-chat/agent-chat';

const Messaging = ({headerHeight}) => {

    const [focusGroup, setFocusGroup] = useState({});
    const [chatList, setChatList] = useState([]);
    const api = useContext(ApiContext)

    useEffect(() => {
        // console.log('[fetchChatList] Fetching chat groups...');
        const fetchChatList = async () => {
            try {
                const data = await api.get(`/api/chat/groups/`)
                console.log('[fetchChatList] Chat groups fetched successfully:', data);
                // console.log(data)
                setChatList(data)
            }catch(error){
                console.error('[fetchChatList] Error fetching chat groups:', error);
                toast.error(`There was an error loading the data: ${error.message}`);
            }
        }
        fetchChatList();
    },[])

    // console.log(focusGroup)

    return (
        <>
            <MessagingHeader>
                <Title>All Messages</Title>
            </MessagingHeader>
            <ContentSection headerHeight={headerHeight}>
                <ChatList
                    chatList = {chatList}
                    setFocusGroup = {setFocusGroup}
                    focusGroupId = {focusGroup.id}
                    setChatList = {setChatList}
                />
                {
                    focusGroup?.id ? (
                        <ChatContentSection>
                            <ChatHeader>
                                {focusGroup.listingName}
                            </ChatHeader>
                            <ChatContainer>
                                <ChatChannel groupId={focusGroup.id} />
                            </ChatContainer>
                        </ChatContentSection>
                        ) : (
                        <NoChatDiv>
                            <NoChatText>
                                Send & Receive messages for Individual Applications & Deals.
                            </NoChatText>
                        </NoChatDiv>
                        )
                }
                
            </ContentSection>
        </>
    )
}

export default Messaging