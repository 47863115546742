import React, { useState } from "react";
import { toProperCase } from "../../utilities/utility";
import { toast } from "react-toastify";
import PopupForm from '../popup-form/popup-form'
import {
    DocumentType, ApplicantContainer, ApplicantHeader, DocumentRow, ButtonDiv, EditButton
} from './document-status-list.styles.'
import {Pencil, Trash2} from "lucide-react";

const DocumentStatusList = ({applicantKey, documents, appLink = null, editMode, handleEdit, handleDelete, applicantId = null, guarantorId = null, applicationId}) => {
    const [url, setUrl] = useState('');
    const copyLink = async () => {
        try {
            await navigator.clipboard.writeText(window.location.origin + appLink);
            toast.success('Link Successfully copied!')
        }catch (err) {
            console.error('Failed to copy text: ', err);
        }
    }
    const handleShare = async () => {
        try {   
            const payload = applicantId ? {applicantId, applicationId} : {guarantorId, applicationId};
            const response = await fetch('/api/application/documents/combine', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        });
        const data = await response.json();
        if(response.ok){
            toast.success('Documents shared successfully!', {
                autoClose: 1500,
            });
        } else {
            toast.error(data?.error || 'Failed to share documents');
        }
    } catch (err) {
        console.error('Failed to share documents: ', err);
    }
    }
    return (
        <ApplicantContainer key={applicantKey}>
            <ApplicantHeader>
                {applicantKey}
                {
                    editMode === true ? 
                        <div style = {{display: 'flex', gap: '5px'}}>
                            <EditButton onClick={handleEdit}> <Pencil size={14} color="#2c2c2c"/> </EditButton>
                            {
                                applicantKey !== "Application Package" &&
                                <EditButton onClick={handleDelete}> <Trash2 size={14} color="red"/> </EditButton>
                            }
                        </div> : 
                    (
                        appLink && appLink !== null && <ButtonDiv onClick={copyLink}>Copy Link</ButtonDiv>
                    )
                }
                {
                    Object.values(documents).filter(v => v != "" && v != null && v != undefined).length > 0 && applicantKey !== "Application Package" && !editMode && !appLink && <ButtonDiv onClick={handleShare}>Share Docs</ButtonDiv>
                }
            </ApplicantHeader>
            {
                Object.entries(documents).map(([documentType, documentStatus]) => {
                    return (
                        <DocumentRow key={toProperCase(documentType)} onClick={() => setUrl({value: documentStatus, docType: documentType})}>
                            <DocumentType className="title">{toProperCase(documentType)}</DocumentType>
                            <div style={{display:"flex", gap: '5px', alignItems:"center"}}>
                                <DocumentType documentStatus = {documentStatus}>{documentStatus? 'Submitted':'Pending'}</DocumentType>
                                {/* <button>upload</button> */}
                            </div>       
                        </DocumentRow>
                    );
                })
            }
            {
                url && url?.value &&
                <PopupForm
                    showPopupUrl = { window.location.hostname === 'localhost'? `http://localhost:8000/media/${url.value}`:`${window.location.origin}/media/${url.value}`}
                    handleClose = {() => setUrl('')}
                    docType={applicantKey + ' - ' + toProperCase(url.docType)}
                />
            }
        </ApplicantContainer>
    )
}

export default DocumentStatusList