import React, { createContext, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const accessTokenRef = useRef(localStorage.getItem('accessToken')); // ✅ Prevents unnecessary re-renders
    const refreshTokenRef = useRef(localStorage.getItem('refreshToken'));
    const intervalRef = useRef(null); // ✅ Prevents multiple intervals

    const setTokens = (access, refresh) => {
        accessTokenRef.current = access;
        refreshTokenRef.current = refresh;
        localStorage.setItem('accessToken', access);
        localStorage.setItem('refreshToken', refresh);
    };

    const login = useCallback(async (username, password) => {
        try {
            const response = await fetch('/api/user/token/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) throw new Error('Login failed');

            const data = await response.json();
            setUser(data);
            setTokens(data.access, data.refresh);

            navigate(data.userType === 'landlord' ? '/leases' : '/showings');
        } catch (error) {
            toast.error('Login failed. Please check your credentials.');
            return false;
        }
    }, [navigate]);

    const logout = useCallback(() => {
        setUser(null);
        setTokens(null, null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/');
    }, [navigate]);

    const refreshTokens = useCallback(async () => {
        if (!refreshTokenRef.current) {
            logout();
            return;
        }

        try {
            const response = await fetch('/api/user/token/refresh/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refresh: refreshTokenRef.current }),
            });

            if (!response.ok) {
                logout();
                return;
            }

            const data = await response.json();
            setTokens(data.access, data.refresh);
        } catch (error) {
            toast.error(`Refresh token error: ${error}`);
            logout();
        }
    }, [logout]);

    const verifyToken = useCallback(async () => {
        if (!accessTokenRef.current) {
            navigate('/');
            return;
        }

        try {
            const response = await fetch('/api/user/profile_details/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessTokenRef.current}`,
                },
            });

            if (response.ok) {
                const userData = await response.json();
                setUser(userData);

                if (location.pathname === '/') {
                    navigate(userData.userType === 'landlord' ? '/leases' : '/showings');
                }
            } else {
                logout();
            }
        } catch (error) {
            toast.error(`Error verifying token: ${error}`);
            logout();
        }
    }, [location.pathname, navigate, logout]);

    useEffect(() => {
        let isMounted = true;
        if (accessTokenRef.current && isMounted) {
            verifyToken();
        }
        return () => { isMounted = false; };
    }, [verifyToken]);

    useEffect(() => {
        // Clear existing interval before setting a new one
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        if (refreshTokenRef.current) {
            intervalRef.current = setInterval(() => {
                refreshTokens();
            }, 1000 * 60 * 15); // Refresh every 15 minutes
        }

        return () => clearInterval(intervalRef.current); // Cleanup interval when component unmounts
    }, [refreshTokens]);

    const value = useMemo(() => ({
        user,
        accessToken: accessTokenRef.current,
        login,
        refreshTokens,
        verifyToken,
        logout,
    }), [user, login, refreshTokens, verifyToken, logout]);

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
